import React from 'react';
import {connect} from 'react-redux';
import {activePremiereUrl, getValidToken} from '../config/apis';
import {setActivePremiere} from '../store/reducers/masterDataSlice';
import {login} from '../store/reducers/userSlice';
import CurrentPremiere from "./currentPremiere";
import NextPremiere from "./nextPremiere";
import UpcomingListed from "./upcomingListed";

function Premiere(props) {
    const [loading, setLoading] = React.useState(true)
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = props.title;
        fetchActivePremieres()
    }, [])
    const fetchActivePremieres = () => {
        getValidToken(props.token).then(token => {
            props.login(token)
            fetch(activePremiereUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token['token']
                }
            }).then(res => {
                if (res.status === 200) {
                    res.json().then(data => {
                        props.setActivePremiere(data)
                    })
                }
                if (res.status === 401) {
                    props.reset()
                }
            }).catch(e => console.log(e)).finally(() => {
                setLoading(false)
            })
        })
    }
    return (
        <>
            {
                loading ?
                    <div className="oneview d-flex">
                        <div className={" d-flex flex-1 justify-content-center align-items-center"}>
                            <h5 className="fw-500 text-white">Loading...</h5>
                        </div>
                    </div>
                    :
                    props.currentPremiere.length === 0 && props.upcomingPremiere.length === 0 ?
                        <div className="oneview d-flex">
                            <div className={" d-flex flex-1 justify-content-center align-items-center"}>
                                <h5 className="fw-500 text-white">No Premiers Scheduled!</h5>
                            </div>
                        </div>
                        :
                        <>
                            {(props.currentPremiere.length > 0) && <CurrentPremiere data={props.currentPremiere}/>}
                            {(props.upcomingPremiere.length > 0) &&
                            <>
                                <NextPremiere data={props.upcomingPremiere[0]}/>
                                <UpcomingListed data={props.upcomingPremiere}/>
                            </>}
                        </>
            }

        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.user.token,
        currentPremiere: state.masterData.activePremieres.current,
        upcomingPremiere: state.masterData.activePremieres.upcoming
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: "RESET_APP"}),
        login: (data) => dispatch(login(data)),
        setActivePremiere: (data) => dispatch(setActivePremiere(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Premiere);
