import React from 'react';
import './player.css'

const Player = (props) => {
    return (
        <>
            <div className={'player br1'}>
                <iframe
                    src={"https://player.vimeo.com/video/" + props.url + "?autoplay=1"}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    className={'playerFrame br1'}
                    referrerPolicy="origin"
                />
            </div>
        </>
    );
};

export default Player;