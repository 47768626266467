import React from "react";
import {isMobileOnly} from "react-device-detect";
import AuthContainer from "./authContainer";
import {Link, useHistory} from "react-router-dom";
import {CPSignUpURL} from "../config/apis";

function ContentProviderSignUp(props) {
    let history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [feedback, setFeedback] = React.useState(0);
    const signUp = (e) => {
        e.preventDefault();
        setFeedback('');
        setLoading(true)
        const formData = new FormData(e.target);
        fetch(CPSignUpURL, {
            method: 'post',
            body: formData
        }).then(resp => {
            if (resp.status === 200) {
                history.push("/success?a=2")
            } else if (resp.status === 409) {
                setFeedback(2)
            }
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <>
            <AuthContainer>
                <h3 className={"mb-2 fw-400"}>SignUp</h3>
                <form onSubmit={signUp}>
                    <label htmlFor={"logo"}>Company Logo</label>
                    <input type="file" accept="image/*" className={'mb-2'} autoComplete="off" required name={"logo"} id={"logo"}/>
                    <div className={isMobileOnly ? "d-flex-column" : "d-flex align-items-center justify-content-between"}>

                        <div className={"mb-2 " + [isMobileOnly ? "w-100" : "w-48"]}>
                            <input type="text" placeholder={"First Name"} required name="first_name"/>
                        </div>
                        <div className={"mb-2 " + [isMobileOnly ? "w-100" : "w-48"]}>
                            <input type="text" placeholder={"Last Name"} required name="last_name"/>
                        </div>
                    </div>
                    <div className={isMobileOnly ? "d-flex-column" : "d-flex align-items-center justify-content-between"}>
                        <div className={"mb-2 " + [isMobileOnly ? "w-100" : "w-48"]}>
                            <input type="text" placeholder={"Company Name"} required name="company_name"/>
                        </div>
                        <div className={"mb-2 " + [isMobileOnly ? "w-100" : "w-48"]}>
                            <input type="text" placeholder={"Tax ID Number"} required name="tan"/>
                        </div>
                    </div>
                    <div className={isMobileOnly ? "d-flex-column" : "d-flex align-items-center justify-content-between"}>
                        <div className={"mb-2 " + [isMobileOnly ? "w-100" : "w-48"]}>
                            <input type="tel" placeholder={"Contact Number"} required name="phone"/>
                        </div>
                        <div className={"mb-2 " + [isMobileOnly ? "w-100" : "w-48"]}>
                            <input type="email" placeholder={"Email"} required name="email"/>
                        </div>
                    </div>
                    <div className={isMobileOnly ? "d-flex-column" : "d-flex align-items-center justify-content-between"}>
                        <div className={"mb-2 " + ["w-100"]}>
                            <input type="password" placeholder={"Create Password"} required name="password"/>
                        </div>
                    </div>
                    <div className={"mb-2 w-100"}>
                        <input type="text" placeholder={"Description"} required name="description"/>
                    </div>
                    <h7 className={feedback === 1 ? "mb-2 text-success" : "mb-2 text-danger"}>{feedback === 1 ? 'SignUp request successfully sent!' : feedback === 2 ? "E-Mail ID already registered!" : ""}</h7>
                    <button className="primary text-white w-100 mt-2 mb-2" disabled={loading}>
                        {loading && <i className="fa fa-circle-notch fa-spin" style={{marginRight: "5px"}}/>}
                        Sign Up
                    </button>
                    <div className={'text-center d-flex align-item-center justify-content-center small'}>
                        User
                        <Link to={'/login'} className={'mx-1 text-initial text-decoration-none fw-500 text-themeStayConnected'}>Login</Link>
                        /
                        <Link to={'/signup'} className={'mx-1 text-initial text-decoration-none fw-500 text-themeStayConnected'}>Signup</Link>
                        here.
                    </div>
                </form>
            </AuthContainer>
        </>
    );
}

export default ContentProviderSignUp;
