import React from "react";
import './nextPremiere.css';
import {isMobile} from "react-device-detect";
import {getLocalDate} from "../config/helper";
import {baseUrl} from "../config/apis";

function NextPremiere(props) {
    const upcomingData = props.data;
    return (
        <section className="nextPremiere container">
            <div className={"nextPremiereThumbnail" + [isMobile ? ' br1' : ' br2']}>
                <img src={baseUrl + upcomingData.still} alt={upcomingData.name}/>
            </div>
            <div className={"nextPremiereDataPanel d-flex-column justify-content-between" + [isMobile ? '' : ' p-3 br2']}>
                <div className="d-flex align-items-start justify-content-between">
                    <button className={'stayConnectedTheme text-white' + [isMobile ? ' small' : '']}>Next Premiere</button>
                </div>
                <div className={'nextPremiereData'}>
                    <p>Premiering On: {getLocalDate(upcomingData.premiereOn)}</p>
                    <p className={'mb-1'}>
                        <small>
                            {
                                (upcomingData.genre ? upcomingData.genre : upcomingData.series.genre).map(item => item.name).join(", ")
                            }
                        </small>
                    </p>
                    {!upcomingData.order //=== "movie"
                        ? isMobile ? <h5 className={'h5'}>{upcomingData.name}</h5> : <h1 className={'h1'}>{upcomingData.name}</h1>
                        : isMobile
                            ? <>
                                <h5 className={'h5'}>{upcomingData.series.name}</h5>
                                <h3 className={'h3'}>{upcomingData.name}</h3>
                            </>
                            : <>
                                <h3 className={'h3'}>{upcomingData.series.name}</h3>
                                <h1 className={'h1'}>{upcomingData.name}</h1>
                            </>
                    }
                    <ul className={'list-style-none d-flex align-items-center my-1'}>
                        <li className="me-1">Age: {!upcomingData.order?upcomingData.rating:upcomingData.series.rating}</li>
                        <li className="me-1">Year: {!upcomingData.order?upcomingData.year:upcomingData.series.year}</li>
                        <li className="me-1">Duration: {upcomingData.duration.split(":")[0] + "Hr " + upcomingData.duration.split(":")[1] + "Min"}</li>
                    </ul>
                    <p className={'line2 text-muted'}>{upcomingData.description}</p>
                </div>
            </div>
        </section>
    )
}

export default NextPremiere;
