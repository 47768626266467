import React from "react";
import {getLocalDate} from "../config/helper";
import "./upcomingListed.css";
import {baseUrl} from "../config/apis";

function UpcomingListed(props) {
    const upcomingData = props.data;
    return (
        <>
            <section className="upcomingListed container mb-5">
                {upcomingData.map((item, index) => {
                    if (index === 0) {
                        return <React.Fragment key={index}></React.Fragment>
                    }
                    return (
                        <div className="card br1" key={index}>
                            <img src={baseUrl + (!item.order ? item.posterWeb : item.series.seasons[0].posterWeb)} alt={item.name} className="thumbnail br1"/>
                            <div className="cardBody p-2">
                                <p className={'mb-2'}>Premiere On: {getLocalDate(item.premiereOn)}</p>
                                <p className={'mb-1'}>
                                    <small> {
                                        (item.genre ? item.genre : item.series.genre).map(item => item.name).join(", ")
                                    }</small>
                                </p>
                                {!item.order //=== "movie"
                                    ? <h5 className={'h5'}>{item.name}</h5>
                                    : <>
                                        <h6 className={'h6'}>{item.series.name}</h6>
                                        <h5 className={'h5'}>{item.name}</h5>
                                    </>
                                }
                                <ul className={'list-style-none d-flex align-items-center my-1 justify-content-between'}>
                                    <li className="me-1 h7">Age: {!item.order ? item.rating : item.series.rating}</li>
                                    <li className="me-1 h7">Year: {!item.order ? item.year : item.series.year}</li>
                                    <li className="me-1 h7">Duration: {item.duration.split(":")[0] + "Hr " + item.duration.split(":")[1] + "Min"}</li>
                                </ul>
                                <p className={'line2 text-muted'}>{item.description}</p>
                            </div>
                        </div>
                    )
                })}
            </section>
        </>
    )
}

export default UpcomingListed;
