import React, {useState} from 'react'
import {isMobileOnly} from "react-device-detect";
import {Link, useHistory} from "react-router-dom";
import {ValidateEmail} from "./signup";
import {forgotPasswordURL} from "../config/apis";
import AuthContainer from "./authContainer";

export default function ForgotPassword(props) {
    React.useEffect(() => {
        document.title = props.title
        window.scrollTo(0, 0)
    }, [props.title])
    const [userEmail, setMail] = useState('')
    const [feedback, setFeedback] = useState(' ')
    const [loading, setLoading] = useState(false)
    let history = useHistory();

    function resetPassword(e) {
        e.preventDefault()
        if (userEmail === '') {
            return
        }
        let temp = userEmail.split('@')
        temp[0] = temp[0].replace('.', '')
        temp = temp[0] + '@' + temp[1]
        if (!ValidateEmail(temp.toLowerCase().trim())) {
            setFeedback('Invalid Email ID')
            return
        }
        setLoading(true)
        setTimeout(() => {
            fetch(forgotPasswordURL + '?mail=' + temp.toLowerCase().trim()).then(response => {
                if (response['status'] === 404) {
                    setFeedback('Invalid Email ID')
                } else {
                    history.push("/enterOTP?mail=" + temp.toLowerCase().trim())
                }
            }).catch(() => {
                setFeedback('Network Error')
            }).finally(() => {
                setLoading(false)
            })
        }, 100)
    }

    return (
        <>
            <AuthContainer>
                <button onClick={useHistory().goBack} className="small theme mb-4">
                    <i className="fas fa-arrow-left me-1"/>Back
                </button>
                <h3 className={'mb-1 fw-300'}>Forgot Password</h3>
                <h6 className={'mb-4 fw-200 ' + [isMobileOnly ? 'small' : '']}>Enter the email address you used when you joined and we will send OTP to verify your mail to reset
                    your password.</h6>
                <form action="#" onSubmit={resetPassword}>
                    <input type="email" placeholder={'Email'} className={'br2 mb-2'} onChange={(e) => setMail(e.target.value)}/>
                    <h7 className={'mb-2 text-danger'}>{feedback}</h7>
                    <button className="stayConnectedTheme text-white w-100 my-2" disabled={loading}>
                        {loading && <i
                            className="fa fa-circle-notch fa-spin"
                            style={{marginRight: "5px"}}
                        />}
                        Send OTP
                    </button>
                    <div className={'text-center d-flex-column align-item-center justify-content-center'}>
                        <p>Remembered Your Password</p>
                        <p><Link to={'/login'} className={'mx-1 text-initial text-decoration-none fw-500 text-themeStayConnected'}>Sign In</Link>
                            Now</p>
                    </div>
                </form>
            </AuthContainer>
        </>
    )
}