import React from 'react';
import {isMobile} from "react-device-detect";
import {getValidToken, watchPartyInviteUrl} from "../../config/apis";
import {connect} from "react-redux";
import {login} from "../../store/reducers/userSlice";
import {AiOutlineLoading} from "react-icons/ai";
import {b64_to_utf8} from "../../config/helper";

const InviteBtn = (props) => {

    const [loading, setLoading] = React.useState(false)
    const [invited, setInvited] = React.useState(false)

    const queryString = window.location.search ? window.location.search : ("?" + window.location.pathname.split("?")[1]);
    let urlParams = new URLSearchParams(queryString);
    urlParams = b64_to_utf8(urlParams.get('data'));
    urlParams = urlParams.split(";");

    React.useEffect(() => {
        if (props.data.find(item => item.email === props.user.user_name)) {
            setInvited(true)
        } else {
            setInvited(false)
        }
    }, [props.data])

    function onInvite(userName) {

        let isMovie = urlParams[1];
        let id = urlParams[0];

        getValidToken(props.token).then(token => {
            props.login(token)
            let body = {
                id: id,
                email: userName,
                isMovie: false
            }
            if (isMovie === "true") {
                body['isMovie'] = true
            }
            if (props.sessionID) {
                body = {
                    sessionID: props.sessionID,
                    email: userName
                }
            }
            setLoading(true)
            fetch(watchPartyInviteUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token['token']
                },
                body: JSON.stringify(body)
            }).then(res => {
                if (res.status === 401) {
                    props.reset()
                }
                if (res.status === 200) {
                    res.json().then(data => {
                        if (!props.sessionID) {
                            props.setSessionID(data.sessionID)
                        }
                    })
                }
            }).finally(() => {
                setLoading(false)
            })
        })
    }

    return (
        <button className={'small d-flex align-items-center ' + [isMobile ? "mt-2 w-100" : ""] + [loading ? " disabled" : invited ? " success" : " primary"]}
                onClick={() => onInvite(props.user.user_name)}
                disabled={loading || invited}
        >
            {
                loading ?
                    <AiOutlineLoading className={'me-1 fa-spin'}/>
                    :
                    <></>
            }
            {invited ? "Invited" : "Invite"}
        </button>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        reset: () => dispatch({type: "RESET_APP"}),
        login: (data) => dispatch(login(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteBtn);