import React from 'react';
import LogoPNG from "../assets/img/stay_connected_Logo.png";
import {isMobileOnly} from "react-device-detect";
import {useLocation} from "react-router-dom";

export default function AuthContainer(props) {
    const location = useLocation();

    return (
        <section className="d-center auth">
            <div className="authProcess br2 p-3">
                <div className="authStaticLogo">
                    <img src={LogoPNG} height={50} alt="Stay Connected TV"/>
                </div>
                <div className="authStaticData d-flex-column justify-content-between">
                    <ul>
                        {
                            location.pathname === "/content_provider_sign_up" || location.search.split("=")[1]==="2" ?
                                <>
                                    <li>Showcase your films and Series</li>
                                    <li>Start your own Pod Cast!</li>
                                    <li>Conduct a Webinars!</li>
                                    <li>Start a Live Training Session with your fans and followers!</li>
                                    <li>Your followers earn money from promoting your channel!</li>
                                </>
                                :
                                <>
                                    <li>Watch exclusive content from your favorite creators.</li>
                                    <li>Earn money from referrals.</li>
                                    <li>Watch Virtual Premieres, Webinars, trainings and Podcasts from your favorite creators.</li>
                                    <li>Create watch parties for you and your friends.</li>
                                </>
                        }

                    </ul>
                    <p className={'copyrightText'}>
                        <small>Copyright © 2020 All Rights Reserved | StayConnectedTv</small>
                    </p>
                </div>
                <div className="authProcessData">
                    <div className={"authProcessDataPanel br2 " + [isMobileOnly ? 'p-2' : 'p-3']}>
                        {props.children}
                    </div>
                </div>
            </div>
        </section>
    )
}