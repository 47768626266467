import moment from "moment";

// export const baseUrl = 'https://stayconnectedtv.com';
export const baseUrl = 'https://dev.webologixglobal.com';

export const CPSignUpURL = baseUrl + '/api/v1/contentProvider/CPSignup/';

export const loginURL = baseUrl + '/api/v1/users/login/';
export const contactUsUrl = baseUrl + '/api/v1/users/contactUs/';
export const GoogleSignInUrl = baseUrl + '/api/v1/users/googleSignIn/';
export const signUpURL = baseUrl + '/api/v1/users/signUp/';
export const forgotPasswordURL = baseUrl + '/api/v1/users/forgotPassword';
export const basicDetailURL = baseUrl + '/api/v1/users/basicDetails';
export const myListURL = baseUrl + '/api/v1/users/myList';
export const recentURL = baseUrl + '/api/v1/users/recent';
export const walletHistoryURL = baseUrl + '/api/v1/users/walletTransaction/';
export const buyUsingWalletURL = baseUrl + '/api/v1/users/buyFromWallet/';
export const effectiveWalletURL = baseUrl + '/api/v1/users/effectiveWallet/';
export const withdrawAmountURL = baseUrl + '/api/v1/users/withdrawAmount/';
export const progressURL = baseUrl + '/api/v1/users/progress/';
export const paymentURL = baseUrl + '/api/v1/users/payment/';
export const referralVerificationURL = baseUrl + '/api/v1/users/ReferralVerification/';
export const paymentTokenURL = baseUrl + '/api/v1/users/paymentToken/';
export const refreshUrl = baseUrl + '/api/v1/refresh/';

export const moviesURL = baseUrl + '/api/v1/masterData/home/';
export const moviesOpenURL = baseUrl + '/api/v1/masterData/homeOpen/';
export const getUrl = baseUrl + '/api/v1/users/getURL/';
export const subsPlanURL = baseUrl + '/api/v1/masterData/subsPlan/';
export const referralURL = baseUrl + '/api/v1/masterData/referral/';
export const searchURL = baseUrl + '/api/v1/masterData/search/';
export const activePremiereUrl = baseUrl + '/api/v1/masterData/activePremiere/';
export const watchPartyUserSearchUrl = baseUrl + '/api/v1/users/userSearch/';
export const watchPartyInviteUrl = baseUrl + '/api/v1/users/invites/';
export const watchPartyMsgUrl = baseUrl + '/api/v1/users/sendMessage/';

export async function getValidToken(tokens) {
    let created_at;
    if (Object.keys(tokens).indexOf('recreated_at') !== -1) {
        created_at = tokens['recreated_at']
    } else {
        created_at = tokens['created_at']
    }
    if ((moment.utc(created_at, 'YYYY-MM-DDTHH.mm.ss').add(moment.duration(tokens['token_expiry'], 'seconds'))).local().isBefore(moment().add(2,"minutes"))) {
        if ((moment.utc(tokens['created_at'], 'YYYY-MM-DDTHH.mm.ss').add(tokens['refresh_expiry'], 'days')).local().isAfter(moment())) {
            let resp = await fetch(refreshUrl, {
                method: 'POST',
                headers:
                    {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                body: JSON.stringify({
                    'refresh': tokens['refresh']
                })
            });
            if (resp.status === 200) {
                let data = await resp.json()
                let tok = JSON.parse(JSON.stringify(tokens))
                tok['token'] = data['access']
                tok['recreated_at'] = moment().utc().format('YYYY-MM-DD[T]HH.mm.ss')
                tok['refresh'] = data['refresh']
                return tok
            }
        } else {
            return null
        }
    }
    return tokens
}

export async function checkValidity(tokens) {
    let created_at;
    if (Object.keys(tokens).indexOf('recreated_at') !== -1) {
        created_at = tokens['recreated_at']
    } else {
        created_at = tokens['created_at']
    }
    if ((moment.utc(created_at, 'YYYY-MM-DDTHH.mm.ss').add(moment.duration(tokens['token_expiry'], 'seconds'))).local().isBefore(moment().add(1,"minutes"))) {
        if ((moment.utc(tokens['created_at'], 'YYYY-MM-DDTHH.mm.ss').add(tokens['refresh_expiry'], 'days')).local().isAfter(moment().add(6, 'hours'))) {
            let resp = await fetch(refreshUrl, {
                method: 'POST',
                headers:
                    {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                body: JSON.stringify({
                    'refresh': tokens['refresh']
                })
            });
            if (resp.status === 200) {
                let data = await resp.json()
                let tok = JSON.parse(JSON.stringify(tokens))
                tok['token'] = data['access']
                tok['recreated_at'] = moment().utc().format('YYYY-MM-DD[T]HH.mm.ss')
                tok['refresh'] = data['refresh']
                return tok
            } else {
                return false
            }
        } else {
            return false
        }
    } else {
        return tokens
    }
}
