import { createSlice } from '@reduxjs/toolkit'
import { masterDataState } from '../initialState';

const masterDataSlice = createSlice({
    name: "masterData",
    initialState: masterDataState,
    reducers: {
        initHome: (state, action) => {
            state.home = action.payload['home']
            state.movies = action.payload['movies']
            state.series = action.payload['series']
        },
        markAddedToList: (state, action) => {
            for (let i = 0; i < state.home.length; i++) {
                let index = state.home[i]['data'].findIndex(value => {
                    return value.id.toString() === action.payload[1].toString() && (action.payload[0] === 'movie' ? !Object.keys(value).includes('seasons') : Object.keys(value).includes('seasons'))
                })
                if (index !== -1) {
                    state.home[i]['data'][index]['inList'] = true
                }
            }
            if (action.payload[0] === 'movie') {
                for (let i = 0; i < state.movies.length; i++) {
                    let index = state.movies[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.movies[i]['data'][index]['inList'] = true
                    }
                }
            } else {
                for (let i = 0; i < state.series.length; i++) {
                    let index = state.series[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.series[i]['data'][index]['inList'] = true
                    }
                }
            }
        },
        markRemovedFromList: (state, action) => {
            for (let i = 0; i < state.home.length; i++) {
                let index = state.home[i]['data'].findIndex(value => {
                    return value.id.toString() === action.payload[1].toString() && (action.payload[0] === 'movie' ? !Object.keys(value).includes('seasons') : Object.keys(value).includes('seasons'))
                })
                if (index !== -1) {
                    state.home[i]['data'][index]['inList'] = false
                }
            }
            if (action.payload[0] === 'movie') {
                for (let i = 0; i < state.movies.length; i++) {
                    let index = state.movies[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.movies[i]['data'][index]['inList'] = false
                    }
                }
            } else {
                for (let i = 0; i < state.series.length; i++) {
                    let index = state.series[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.series[i]['data'][index]['inList'] = false
                    }
                }
            }
        },
        setSubs: (state, action) => {
            state.subs = action.payload
        },
        setReferral: (state, action) => {
            state.referral = action.payload
        },
        setActivePremiere: (state, action) => {
            state.activePremieres = action.payload
        },
    }
});
export const { initHome, markAddedToList, markRemovedFromList, setSubs, setReferral, setActivePremiere } = masterDataSlice.actions
export default masterDataSlice.reducer