export const userState = {
    token: null,
    basicDetails: {
        user: {},
        subscription: {}
    },
    list: {
        results: 20,
        page: 1,
        data: []
    },
    recent: []
}

export const masterDataState = {
    home: [],
    movies: [],
    series: [],
    subs: [],
    referral: {},
    activePremieres: {
        current: [],
        upcoming: []
    },
}