import React from 'react';
import "./messages.css"
import {doc, onSnapshot, getDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import moment from "moment";
import {getAuth} from "firebase/auth";
import {AiOutlineLoading} from "react-icons/ai";
import {getValidToken, watchPartyMsgUrl} from "../../config/apis";
import {connect} from "react-redux";
import {login} from "../../store/reducers/userSlice";

const PartyMessages = (props) => {
    const dataRef = React.useRef();
    const [show, setShow] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [messages, setMessages] = React.useState([])
    const [active, setActive] = React.useState(2)
    const [activity, setActivity] = React.useState({})

    React.useEffect(() => {
        if (props.isHost) {
            let unsub;
            setTimeout(() => {
                unsub = onSnapshot(doc(db, "watchParty", props.sessionID), (docRef) => {
                    let data = docRef.data()
                    setMessages(data.messages)
                });
            }, 1000)
            return () => {
                if (unsub) {
                    unsub()
                }
            }
        }
    }, [props.isHost])

    React.useEffect(() => {
        if (props.isHost === false) {
            setMessages(props.messages)
        }
    }, [props.isHost, props.messages])

    React.useEffect(() => {
        dataRef.current.scrollTop = dataRef.current.scrollHeight
    }, [messages])

    React.useEffect(() => {
        updateActiveUsers()
        let intv = setInterval(updateActiveUsers, 30000)
        return () => clearInterval(intv)
    }, [props.participants])

    const updateActiveUsers = React.useCallback(() => {
        let users = {}
        for (let i = 0; i < props.participants.length; i++) {
            getDoc(doc(db, "userActivity", props.participants[i].uid)).then((docRef) => {
                if (docRef.exists()) {
                    let data = docRef.data()
                    if (data.sessionID === props.sessionID)
                        users[props.participants[i].uid] = new Date(docRef.data().lastActive.seconds * 1000)
                }
            }).catch(err => console.log(err));
        }
        setActivity(users)
    }, [props.participants])

    const sendMessage = () => {
        setLoading(true)
        getValidToken(props.token).then(token => {
            props.login(token)
            document.getElementById("wpMsg").disabled = true
            fetch(watchPartyMsgUrl, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token['token']
                },
                body: JSON.stringify({
                    text: document.getElementById("wpMsg").value,
                    sessionID: props.sessionID
                })
            }).then(resp => {
                if (resp.status === 401) {
                    props.reset();
                }
            }).finally(() => {
                document.getElementById("wpMsg").disabled = false
                document.getElementById("wpMsg").value = ''
                setLoading(false)
            })
        })
    }

    return (
        <>
            <div onClick={() => setShow(true)}>
                <i className="fas fa-comments fa-lg p-2"/>
            </div>
            <div className={"px-2 pt-2 d-flex-column messageWindow" + [show ? " active" : ""]}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <div>
                        <h6>Watch Party Chat</h6>
                    </div>
                    <div onClick={() => setShow(false)}>
                        <i className="fas fa-times fa-md p-2"/>
                    </div>
                </div>
                {
                    props.isHost &&
                    <div className={"d-flex-column mt-2"}>
                        <div className={"d-flex"}>
                            <div className={"flex-1 p-2 tab1"} onClick={() => setActive(1)}>
                                <p className={"text-center"}>Participants</p>
                            </div>
                            <div className={"flex-1 p-2 tab2"} onClick={() => setActive(2)}>
                                <p className={"text-center"}>Messages</p>
                            </div>
                        </div>
                        <hr className={"activeBar br2 " + "tab" + active}/>
                    </div>
                }

                <div className={"flex-1 messages overflowY pt-2 " + [active === 1 ? "d-none" : ""]} ref={dataRef}>
                    {
                        messages.map((value, index) => {
                            return <div className={'p-2 br1 mb-2 mx-2 w-75' + [value.uid !== getAuth().currentUser.uid ? " bg-primary" : " bg-success ms-auto"]} key={index}>
                                <div className={'mb-2 pb-2 border-bottom justify-content-between d-flex'}>
                                    <span>
                                        {value.uid === getAuth().currentUser.uid ?
                                            <><i className="fas fa-reply fa-sm me-2"/>Your Reply</>
                                            :
                                            <> <i className="fas fa-paper-plane fa-sm me-2"/>{value.name}</>
                                        }
                                    </span>
                                    {moment(new Date(value.sentOn.seconds * 1000)).local().format("Do MMM, hh:mmA")}
                                </div>
                                <p>{value.text}</p>
                            </div>
                        })
                    }
                </div>
                <div className={"flex-1 messages overflowY pt-2 " + [active === 2 ? "d-none" : ""]}>
                    {
                        props.participants.map((value, index) => {
                            return <div className={' d-flex align-items-center justify-content-between p-2 br1 mb-2 mx-2 bg-theme'} key={index}>
                                <div>
                                    <p>{value.name}</p>
                                    <p>{value.email}</p>
                                </div>
                                <div>
                                    {
                                        value.status === 0 ?
                                            <span className={"text-danger"}>Rejected</span>
                                            :
                                            value.status === -1 ?
                                                <span className={"text-primary"}>Invited</span>
                                                :
                                                activity[value.uid] ?
                                                    moment(activity[value.uid]).isAfter(moment().subtract(2, "minutes")) ?
                                                        <span className={"d-flex align-items-center"}><div className={"bg-success badge me-1"}/>Active</span>
                                                        :
                                                        <span className={"d-flex align-items-center"}><div className={"bg-danger badge me-1"}/>Offline</span>
                                                    :
                                                    <span className={"d-flex align-items-center"}><div className={"bg-danger badge me-1"}/>Offline</span>
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className={"d-flex align-items-center my-2 pb-2 " + [active === 1 ? "d-none" : ""]}>
                    <input placeholder={"message"} className={"w-100 me-1"} id={"wpMsg"}/>
                    <button className={"d-flex primary ms-1"} onClick={sendMessage} disabled={loading}>
                        {loading && <AiOutlineLoading className={'me-1 fa-spin'}/>}Send
                    </button>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = state => {
    return {
        token: state.user.token,
        basicDetails: state.user.basicDetails
    }
}
const mapDispatchToProps = dispatch => {
    return {
        reset: () => dispatch({type: "RESET_APP"}),
        login: (data) => dispatch(login(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PartyMessages);