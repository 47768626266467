import React from 'react'
import {Switch, Route, Redirect} from "react-router-dom";
import HomeIndex from "./home/home";
import Profile from "./profile/profile";
import TermsAndCondition from "./common/terms&Conditions";
import PrivacyPolicy from "./common/privacyPolicy";
import ItemData from "./itemDetails/itemDetails";
import {checkValidity, moviesOpenURL, moviesURL} from "./config/apis";
import {connect} from "react-redux";
import {login} from "./store/reducers/userSlice";
import {initHome} from "./store/reducers/masterDataSlice";
import LogoPNG from "./assets/img/stay_connected_Logo.png";
import FAQs from "./common/faq";
import ContactUs from "./contact/contact";
import Premiere from "./premiere";
import WatchParty from "./watchPartyInvite";

//Components
function Routing(props) {
    React.useEffect(() => {
        // props.reset()
        if (props.token !== null) {
            checkValidity(props.token).then(status => {
                if (status === false) {
                    props.reset();
                    homeOpen();
                } else {
                    home(status)
                }
                setTimeout(() => {
                    props.setValidating(false)
                }, 2000)
            })
        } else {
            homeOpen();
            setTimeout(() => {
                props.setValidating(false)
            }, 2000)
        }
    }, [])
    const home = (token) => {
        props.login(token)
        fetch(moviesURL, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token['token']
            },
        }).then(resp => {
            resp.json().then(data => {
                props.initHome(data)
            })
        })
    }

    const homeOpen = () => {
        fetch(moviesOpenURL, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(resp => {
            resp.json().then(data => {
                props.initHome(data)
            })
        })
    }
    if (props.validating) {
        return <div className={"w-100 h-100 align-items-center d-flex-column justify-content-center"} style={{position: "absolute", backgroundColor: 'black', zIndex: 100}}>
            <img src={LogoPNG} alt="Logo" style={{width: '75%', objectFit: 'contain'}}/>
            <div>
                <i className="fa fa-circle-notch fa-spin fa-2x"/>
            </div>

        </div>
    }
    return (
        <Switch>
            <Route
                exact
                path="/"
                render={props => (<HomeIndex component={HomeIndex} title="Stay Connected TV" {...props}/>)}
            />
            <Route
                exact
                path="/item_details"
                render={props => (<ItemData component={ItemData} title="Stay Connected TV" {...props}/>)}
            />
            <Route
                exact
                path="/terms_and_conditions"
                render={props => (<TermsAndCondition component={TermsAndCondition} title={'Terms & Conditions | Stay Connected TV'} {...props}/>)}
            />
            <Route
                exact
                path="/faq"
                render={props => (<FAQs component={FAQs} title={'FAQ | Stay Connected TV'} {...props}/>)}
            />
            <Route
                exact
                path="/contactUs"
                render={props => (<ContactUs component={ContactUs} title={'Contact Us | Stay Connected TV'} {...props}/>)}
            />
            <Route
                exact
                path="/privacy_policy"
                render={props => (<PrivacyPolicy component={PrivacyPolicy} title={'Privacy Policy | Stay Connected TV'} {...props}/>)}
            />

            <Route
                path="/profile"
                render={props => (<Profile component={Profile} title="Profile | Stay Connected TV" {...props}/>)}
            />
            <Route
                exact
                path="/premiere"
                render={props => (<Premiere component={Premiere} title={"Premiere | Stay Connected TV"} {...props} />)}
            />
            <Route
                exact
                path="/watch_party_invite"
                render={props => (<WatchParty component={WatchParty} title={"Watch Party Invite | Stay Connected TV"} {...props} />)}
            />
            <Redirect to="/"/>
        </Switch>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
        list: state.user.list,
        home: state.masterData.home,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: 'RESET_APP'}),
        login: (data) => dispatch(login(data)),
        initHome: (data) => dispatch(initHome(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Routing);
