import React from "react"
import './header.css'
import {Link, useLocation} from "react-router-dom";
import LogoPNG from '../assets/img/stay_connected_Logo.png'
import {isMobile} from "react-device-detect";
import Search from "../components/search";
import {connect} from "react-redux";

const NavigationData = [
    {
        link: "/",
        name: "Home"
    },
    {
        link: {pathname: '/', type: 'movies'},
        name: "Movies"
    },
    {
        link: {pathname: '/', type: 'series'},
        name: "Series"
    }
]

function Header(props) {
    const Location = useLocation();
    const [search, setSearch] = React.useState(false);
    const [sidebar, setSidebar] = React.useState(false);
    window.addEventListener("scroll", function () {
        let header = document.getElementById('mainHeader')
        if (header)
            header.classList.toggle("sticky", 0 < window.scrollY);
    })
    const showSidebar = () => setSidebar(!sidebar);
    return (
        <>
            <header className={'main'} id={'mainHeader'}>
                <div className={"d-flex align-items-center justify-content-between h-100" + [isMobile ? ' container' : ' px-4']}>
                    <Link to={'/'} className={'fw-500 text-initial text-decoration-none'}>
                        <img src={LogoPNG} alt="Logo" className={'logo'}/>
                    </Link>
                    <div className={"d-flex align-items-center h-100 " + [sidebar ? 'position-relative' : '']}>
                        <button
                            onClick={() => setSearch(true)}
                            className={'search small theme' + [isMobile ? " square circle me-1" : ' h-100 br0 me-2']}
                            id="searchbutton"
                        >
                            {isMobile
                                ? <i className="fas fa-search"/>
                                : <>
                                    <i className="fas fa-search me-1"/>Search
                                </>
                            }
                        </button>
                        <button onClick={showSidebar} className={"theme square " + [isMobile ? '' : 'd-none']}>
                            <i className="fas fa-bars"/>
                        </button>
                        <ul className={isMobile ? "mobileView" + [sidebar ? " active" : ""] : "webView h-100"}>
                            {isMobile
                                ? <div className={"sidebarHeader"}>
                                    <button onClick={() => setSearch(true)} className={'search small theme'} id="searchbutton">
                                        <i className="fas fa-search me-1"/>Search
                                    </button>
                                    <button onClick={showSidebar} className={"theme square " + [isMobile ? '' : 'd-none']}>
                                        <i className="fas fa-bars"/>
                                    </button>
                                </div>
                                : <></>
                            }
                            {NavigationData.map((item, index) => {
                                let addClass;
                                switch (item.name) {
                                    case "Home":
                                        addClass = Location.pathname === '/' && !Location.type ? ' active' : ''
                                        break;
                                    case "Movies":
                                        addClass = Location.pathname === '/' && Location.type === 'movies' ? ' active' : ''
                                        break;
                                    case "Series":
                                        addClass = Location.pathname === '/' && Location.type === 'series' ? ' active' : ''
                                        break;
                                }
                                return <li className={[isMobile ? index === 0 ? 'mt-4' : '' : 'h-100 d-center '] + [addClass]} onClick={showSidebar} key={index}>
                                    <Link to={item.link} className={'fw-400 text-capitalize text-initial text-decoration-none' + [isMobile ? ' small' : ' h-100 d-center px-2']}>
                                        {item.name}
                                    </Link>
                                </li>
                            })}
                            <li className={isMobile ? '' : 'h-100 d-center' + [Location.pathname === '/premiere' ? ' active' : '']}>
                                <Link to={'/premiere'} className={'fw-400 text-capitalize text-initial text-decoration-none' + [isMobile ? ' small' : ' h-100 d-center px-2']}>
                                    Premiere
                                </Link>
                            </li>
                            <li className={isMobile ? '' : 'h-100 d-center' + [(Location.pathname.startsWith('/profile') || Location.pathname === '/login') ? ' active' : '']} onClick={showSidebar}>
                                {props.token
                                    ? <Link to={"/profile"} className={'fw-400 text-capitalize text-initial text-decoration-none' + [isMobile ? ' small' : ' h-100 d-center px-2']}>Profile</Link>
                                    : <Link to={"/login"} className={'fw-400 text-capitalize text-initial text-decoration-none' + [isMobile ? ' small' : ' h-100 d-center px-2']}>Login</Link>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            {search === true ? <Search onClose={() => setSearch(false)}/> : <></>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.user.token,
    }
}

export default connect(mapStateToProps)(Header)
