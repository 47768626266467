import {baseUrl} from "../config/apis";
import {Link} from "react-router-dom";
import React from "react";

export default function VideoBlock(props) {
    let item = props.item
    if (item.movie) {
        item = item.movie
    } else if (item.series) {
        item = item.series
    }
    return (
        <Link
            to={'/item_details?type=' + (item.seasons ? item.seasons.length ? 'series' : 'movie' : 'movie') + '&item=' + item.id + (props.myList ? 'x' : props.recent ? 'y' : '')}>
            <div className={'videoBlock'}>
                <div className="overlay br1"/>
                <img src={baseUrl + (item.seasons ? item.seasons[0] ? item.seasons[0]['posterWeb'] : item.posterWeb : item.posterWeb)}
                     className={'br1'} alt=""/>
                <div className="videoBlockData br1 p-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <button className="small">
                            <i className="fas fa-play me-1"/>Play
                        </button>
                    </div>
                </div>
                <div className={'videoViewBar br1'} style={{width: parseInt(item.progress ?? 0) + "%"}}/>
            </div>
        </Link>

    )
}