import {createStore} from 'redux'
import rootReducer from './reducers/rootReducer'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const configureStore = preLoadedState => {
    const mainReducer = (state, action) => {
        if (action.type === 'RESET_APP') {
            state = preLoadedState;
        }
        return rootReducer(state, action);
    }
    const persistedReducer = persistReducer(persistConfig, mainReducer)
    return createStore(persistedReducer, preLoadedState)
}

export default configureStore