import React, {useState} from "react";
import {isMobileOnly} from "react-device-detect";
import {Link, useHistory} from "react-router-dom";
import {forgotPasswordURL} from "../config/apis";
import AuthContainer from "./authContainer";

export default function CreateNewPassword(props) {
    React.useEffect(() => {
        document.title = props.title
        window.scrollTo(0, 0)
    }, [props.title])
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [feedback, setFeedback] = useState('')
    const [loading, setLoading] = useState(false)
    let history = useHistory();

    function submit(e) {
        e.preventDefault()
        if (password !== password2 || password === '') {
            setFeedback('Passwords do not match!')
            return
        }
        setLoading(true)
        setTimeout(() => {
            fetch(forgotPasswordURL, {
                method: "PUT",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mail: window.location.search.split('=')[1],
                    otp: window.location.search.split('=')[0].replace('?', ''),
                    password: password
                })
            }).then(response => {
                if (response['status'] === 401) {
                    setFeedback('OTP Expired')
                } else {
                    history.push('/success')
                }
            }).catch(() => {
                setFeedback('Network Error')
            }).finally(() => {
                setLoading(false)
            })
        }, 100)
    }

    return (
        <>
            <AuthContainer>
                <Link to={'/'}>
                    <button className="small theme mb-4">
                        <i className="fas fa-home me-1"/>Home
                    </button>
                </Link>
                <h3 className={'mb-1 fw-300'}>Create New Password</h3>
                <h6 className={'mb-4 fw-200 ' + [isMobileOnly ? 'small' : '']}>Please setup your New Password for your account.</h6>
                <form action="#" onSubmit={submit}>
                    <input type="text" placeholder={'Create New Password'} className={'br2 mb-2'} onChange={e => setPassword(e.target.value)}/>
                    <input type="text" placeholder={'Verify New Password'} className={'br2 mb-2'} onChange={e => setPassword2(e.target.value)}/>
                    <h7 className={'mb-2 text-danger'}>{feedback}</h7>
                    <button className="stayConnectedTheme text-white w-100 mt-2" disabled={loading}>
                        {loading && <i
                            className="fa fa-circle-notch fa-spin"
                            style={{marginRight: "5px"}}
                        />}
                        Save New Password
                    </button>
                </form>
            </AuthContainer>
        </>
    )
}