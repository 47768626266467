import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "./auth/login";
import SignUp from "./auth/signup";
import ForgotPassword from "./auth/forgotPassword";
import EnterOTP from "./auth/enterOTP";
import CreateNewPassword from "./auth/crateNewPassword";
import Success from "./auth/success";
import FullPlayer from "./player/fullPlayer";
import Header from "./navigation/header";
import Routing from "./links";
import Footer from "./navigation/footer";
import ScrollTop from "./components/scrollTop";
import {connect} from "react-redux";
import ContentProviderSignUp from "./auth/contentProviderSignUp";
import PrivacyPolicy from "./common/privacyPolicy";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import PremierPlayer from "./player/premierPlayer";
import PartyPlayer from "./player/partyPlayer/partyPlayer";

const LinkSwitch = (props) => {
    const [validating, setValidating] = React.useState(true);
    const [userLog, setUserLog] = React.useState(true);
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUserLog(true);
        } else {
            setUserLog(false);
        }
    });
    React.useEffect(() => {
        if (!props.token) {
            require("./auth.css");
        }
    }, [props.token]);
    return (
        <>
            {props.token && userLog ? (
                <>
                    <Switch>
                        <Route
                            exact
                            path="/play/:name"
                            render={(props) => (
                                <FullPlayer
                                    component={FullPlayer}
                                    title={"Stay Connected TV"}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/premier/:name"
                            render={(props) => (
                                <PremierPlayer
                                    component={PremierPlayer}
                                    title={"Stay Connected TV"}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/watchParty/:sessionID"
                            render={(props) => (
                                <PartyPlayer
                                    component={PartyPlayer}
                                    title={"Stay Connected TV"}
                                    {...props}
                                />
                            )}
                        />
                        <Route path={"/"}>
                            {validating ? <></> : <Header/>}
                            <Routing validating={validating} setValidating={setValidating}/>
                            <Footer/>
                        </Route>
                    </Switch>
                    <ScrollTop/>
                </>
            ) : (
                <>
                    <link rel="stylesheet" type="text/css" href="/auth.css"/>
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            render={(props) => (
                                <Login
                                    component={Login}
                                    title="Login | Stay Connected TV"
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/signup"
                            render={(props) => (
                                <SignUp
                                    component={SignUp}
                                    title="SignUp | Stay Connected TV"
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/forgotPassword"
                            render={(props) => (
                                <ForgotPassword
                                    component={ForgotPassword}
                                    title="ForgotPassword | Stay Connected TV"
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/enterOTP"
                            render={(props) => (
                                <EnterOTP
                                    component={EnterOTP}
                                    title="Enter OTP | Stay Connected TV"
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/createNewPassword"
                            render={(props) => (
                                <CreateNewPassword
                                    component={CreateNewPassword}
                                    title="Create New Password | Stay Connected TV"
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/success"
                            render={(props) => (
                                <Success
                                    component={Success}
                                    title="Success | Stay Connected TV"
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/content_provider_sign_up"
                            render={(props) => (
                                <ContentProviderSignUp
                                    component={ContentProviderSignUp}
                                    title={"Sign Up | Stay Connected TV"}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/privacy_policy"
                            render={props => (<PrivacyPolicy component={PrivacyPolicy} title={'Privacy Policy | Stay Connected TV'} {...props}/>)}
                        />
                        <Route>
                            <Redirect to="/login"/>
                        </Route>
                    </Switch>
                </>
            )}
        </>
    );
};

const mapStateToProps = state => {
	return {
		token: state.user.token,
	}
}

export default connect(mapStateToProps)(LinkSwitch);