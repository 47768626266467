import React from "react";
import './index.css';
import {isMobile} from "react-device-detect";
import {getAuth} from 'firebase/auth';
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../config/firebase";
import {connect} from "react-redux";
import {getValidToken, watchPartyInviteUrl} from "../../config/apis";
import {login} from "../../store/reducers/userSlice";
import {utf8_to_b64} from "../../config/helper";
import {Link} from "react-router-dom";
import {AiOutlineLoading} from "react-icons/ai";

const Block = (props) => {
    return (
        <div className={"block " + [props.className ? props.className : ""]}>
            <div className="blockTitle pb-2">
                <h5 className="h5">{props.title}</h5>
            </div>
            <div className={"blockBody" + [isMobile ? '' : ' pe-2']}>
                {props.children}
            </div>
        </div>
    )
}

function MyWatchParty(props) {
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [btnLoading, setBtnLoading] = React.useState(false)
    const [pendingInvites, setPendingInvites] = React.useState([])
    const [activeInvites, setActiveInvites] = React.useState([])
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = props.title
    }, [])
    React.useEffect(() => {
        const unsub = onSnapshot(doc(db, "userInvites", getAuth().currentUser.uid), (doc) => {
            if (doc.exists())
                setData(doc.data().invites)
            setLoading(false)
        }, (error) => {
            setLoading(false)
        });
        return unsub
    }, [])
    React.useEffect(() => {
        let pending = data.filter(item => item.status === -1)
        let active = data.filter(item => item.status === 1)
        setPendingInvites(pending)
        setActiveInvites(active)
    }, [data])

    function onUpdateStatus(sessionID, status) {
        getValidToken(props.token).then(token => {
            props.login(token)
            setBtnLoading(true)
            fetch(watchPartyInviteUrl, {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token['token']
                },
                body: JSON.stringify({sessionID, status})
            }).then(res => {

            }).finally(() => {
                setBtnLoading(false)
            })
        })
    }

    return (
        <>
            {
                loading ?
                    <div className={"watchParty-loading justify-content-center align-items-center"}>
                        <h6>Loading...</h6>
                    </div>
                    :
                    activeInvites.length === 0 && pendingInvites.length === 0 ?
                        <div className={"watchParty-loading justify-content-center align-items-center"}>
                            <h6>No Active Watch Party!</h6>
                        </div>
                        :
                        <section className="myWatchParty container my-5">
                            <div className="activeParty">
                                <Block title={'Active Party'} className={activeInvites.length === 0 && "bg-themeMuted br2 p-2"}>
                                    {activeInvites.map((item, index) => {
                                        return (
                                            <div className={'p-2 br2 bg-themeMuted mb-2'}>
                                                <div className={'align-items-start'}>
                                                    <p className="fw500 text-muted">Hosted By</p>
                                                    <p className="fw-500">{item.host.name}</p>
                                                </div>
                                                <div className="my-2">
                                                    {!item.asset.order
                                                        ? <h3 className={'h3'}>{item.asset.name}</h3>
                                                        : <>
                                                            <h5 className={'h5'}>{item.asset.name}</h5>
                                                            <h3 className={'h3'}>Episode title</h3>
                                                        </>
                                                    }
                                                    <ul className={'list-style-none d-flex align-items-center my-1'}>
                                                        <li className="me-1">Age: {item.asset.rating}</li>
                                                        <li className="me-1">Year: {item.asset.year}</li>
                                                        <li className="me-1">Duration: {item.asset.duration}</li>
                                                    </ul>
                                                    <p className={'line2 text-muted'}>{item.asset.description}</p>
                                                </div>
                                                <div className="d-flex align-items-end">
                                                    {
                                                        props.basicDetails.subscription.name ?
                                                            <Link className={"text-decoration-none"} to={{
                                                                pathname: '/watchParty/' + item.sessionID + '?payload=' + utf8_to_b64(item.asset.id + ";" + item.isMovie)
                                                            }}>
                                                                <button className={'small success'}>Join Now</button>
                                                            </Link>
                                                            :
                                                            <Link to={'/profile'}>
                                                                <button className={"small me-2"}>
                                                                    Get Subscribed
                                                                </button>
                                                            </Link>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })}
                                </Block>
                            </div>
                            <div className="pendingInvites">
                                <Block title={'Pending Invite'} className={pendingInvites.length === 0 && "bg-themeMuted br2 p-2"}>
                                    {pendingInvites.map((item, index) => {
                                        return (
                                            <div className={'p-2 br2 bg-themeMuted mb-2'}>
                                                <div className={'align-items-start'}>
                                                    <p className="fw500 text-muted">Hosted By</p>
                                                    <p className="fw-500">{item.host.name}</p>
                                                </div>
                                                <div className="my-2">
                                                    {!item.asset.order
                                                        ? <h3 className={'h3'}>{item.asset.name}</h3>
                                                        : <>
                                                            <h5 className={'h5'}>{item.asset.name}</h5>
                                                            <h3 className={'h3'}>Episode title</h3>
                                                        </>
                                                    }
                                                    <ul className={'list-style-none d-flex align-items-center my-1'}>
                                                        <li className="me-1">Age: {item.asset.rating}</li>
                                                        <li className="me-1">Year: {item.asset.year}</li>
                                                        <li className="me-1">Duration: {item.asset.duration}</li>
                                                    </ul>
                                                    <p className={'line2 text-muted'}>{item.asset.description}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <button
                                                        className={'small bg-theme text-danger w-48 py-2'}
                                                        onClick={() => onUpdateStatus(item.sessionID, 0)}
                                                        disabled={btnLoading}
                                                    >
                                                        {btnLoading && <AiOutlineLoading className={'me-1 fa-spin'}/>}
                                                        Reject
                                                    </button>
                                                    <button
                                                        className={'small bg-theme text-success w-48 py-2'}
                                                        onClick={() => onUpdateStatus(item.sessionID, 1)}
                                                        disabled={btnLoading}
                                                    >
                                                        {btnLoading && <AiOutlineLoading className={'me-1 fa-spin'}/>}
                                                        Accept
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Block>
                            </div>
                        </section>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.user.token,
        basicDetails: state.user.basicDetails
    }
}
export default connect(mapStateToProps, {login})(MyWatchParty);
