import React, {useState} from 'react'
import {Link, useHistory} from "react-router-dom";
import {isMobileOnly} from "react-device-detect";
import {signUpURL} from "../config/apis";
import AuthContainer from "./authContainer";


export function ValidateEmail(mail) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
}

export default function SignUp(props) {
    let history = useHistory();
    const [first_name, setFName] = useState('')
    const [last_name, setLName] = useState('')
    const [mail, setMail] = useState('')
    const [referral, setReferral] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [feedback, setFeedback] = useState(' ')
    const [loading, setLoading] = useState(false)

    function signUp(e) {
        e.preventDefault();
        let temp = mail.split('@')
        temp[0] = temp[0].replace('.', '')
        temp = temp[0] + '@' + temp[1]
        if (!ValidateEmail(temp)) {
            setFeedback('Invalid Email ID')
            return
        }
        setLoading(true)
        setTimeout(() => {
            fetch(signUpURL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'first_name': first_name,
                    'last_name': last_name,
                    'mail': temp.trim().toLowerCase(),
                    'phone': phone,
                    'password': password,
                    'referral': referral,
                })
            }).then(response => {
                if (response['status'] === 409) {
                    setFeedback('Email is already in use')
                } else if (response['status'] === 200) {
                    history.push("/success?a=1")
                } else {
                    setFeedback('Something went wrong!')
                }
            }).catch(() => {
                setFeedback('Network Error!')
            }).finally(() => {
                setLoading(false)
            })
        }, 100)
    }

    React.useEffect(() => {
        document.title = props.title
        window.scrollTo(0, 0)
    }, [props.title])
    return (
        <>
            <AuthContainer>
                <h3 className={'mb-2 fw-400'}>SignUp</h3>
                <form onSubmit={signUp}>
                    <div className={isMobileOnly ? 'd-flex-column' : 'd-flex align-items-center justify-content-between'}>
                        <div className={'mb-2 ' + [isMobileOnly ? 'w-100' : 'w-48']}>
                            <input type="text" placeholder={'First Name'} onChange={(e) => setFName(e.target.value)} required/>
                        </div>
                        <div className={'mb-2 ' + [isMobileOnly ? 'w-100' : 'w-48']}>
                            <input type="text" placeholder={'Last Name'} onChange={(e) => setLName(e.target.value)} required/>
                        </div>
                    </div>
                    <input type="email" placeholder={'Email'} className="mb-2" onChange={(e) => setMail(e.target.value)} required/>
                    <div className={isMobileOnly ? 'd-flex-column' : 'd-flex align-items-center justify-content-between'}>
                        <div className={'mb-2 ' + [isMobileOnly ? 'w-100' : 'w-48']}>
                            <input type="tel" placeholder={'Phone Number'} onChange={(e) => setPhone(e.target.value)} required/>
                        </div>
                        <div className={'mb-2 ' + [isMobileOnly ? 'w-100' : 'w-48']}>
                            <input type="text" placeholder={'Referral Code'} onChange={(e) => setReferral(e.target.value)}/>
                        </div>
                    </div>
                    <input type="password" placeholder={'Create Password'} className={'mb-2'} onChange={(e) => setPassword(e.target.value)} required/>
                    <h7 className={'mb-2 text-danger'}>{feedback}</h7>
                    <button className="primary text-white w-100 mt-2 mb-2" disabled={loading}>
                        {loading && <i
                            className="fa fa-circle-notch fa-spin"
                            style={{marginRight: "5px"}}
                        />}
                        Sign Up
                    </button>
                    <div className={'text-center d-flex align-item-center justify-content-center'}>
                        Already have an account?
                        <Link to={'/login'} className={'ms-1 text-initial text-decoration-none fw-500 text-primary'}>Sign In</Link>
                    </div>
                </form>
            </AuthContainer>
        </>
    )
}