import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import configureStore from "./store/configureStore";
import {masterDataState, userState} from "./store/initialState";
import {Provider} from "react-redux";
import {persistStore} from "redux-persist";
import {PersistGate} from 'redux-persist/integration/react'
import LinkSwitch from "./linkSwitch";
import './config/firebase';

export const store = configureStore({user: userState, masterData: masterDataState});
export const persistor = persistStore(store)

function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router>
                    <LinkSwitch/>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
