import React from "react";
import './search.css'
import {isMobile} from "react-device-detect";
import {baseUrl, searchURL} from "../config/apis";
import {connect} from "react-redux";
import '../profile/manageList.css'
import {Link} from "react-router-dom";

function Search(props) {
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    React.useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    React.useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
    }, [])
    const [text, setText] = React.useState('')
    const [data, setData] = React.useState([])

    function search(e) {
        e.preventDefault();
        if (text) {
            fetch(searchURL + '?text=' + text, {
                headers:
                    props.token ?
                        {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.token['token']
                        }
                        : {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        }
            }).then(r => {
                if (r.status === 200) {
                    r.json().then(data => {
                        setData(data)
                    })
                }
            })
        }
    }

    return (
        <section className={'searchPage d-center'} onClick={props.onClose}>
            <form className={'searchForm'} onClick={e => e.stopPropagation()} onSubmit={search}>
                <h3 className="fw-300 mb-2 text-transform-capitalize">Search Anything to watch...</h3>
                <div className="d-flex">
                    <input type="text" placeholder={'Search'} onChange={(e) => setText(e.target.value)}/>
                    <button className="small"><i className="fas fa-search"/></button>
                </div>
            </form>
            <div className={'container'} style={{height: '75vh', overflow: 'scroll'}} onClick={e => e.stopPropagation()}>
                {
                    data.map((item, i) => {
                        return <div className="videoItemList p-2 my-2 br2" key={i}>
                            <Link to={'/item_details?type=movie&item=' + item.id} className="videoItemList-img position-relative cursor text-white" onClick={props.onClose}>
                                <img src={baseUrl + item.posterWeb} className={'br1'} alt=""/>
                                <div className="playerIcon d-center br1">
                                    <i className="fas fa-play-circle fa-2x"/>
                                </div>
                            </Link>
                            <div className={'videoItemList-data'}>
                                <h4 className={'text-transform-capitalize'}>{item.name}</h4>
                                <p className={'text-muted fw-500 ' + [isMobile ? 'small' : '']}>{item.description}</p>
                            </div>
                        </div>
                    })
                }
            </div>
            <button onClick={props.onClose} className={"small theme searchPageCloseButton" + [isMobile ? ' square circle' : '']}>
                {isMobile
                    ? <i className="fas fa-times"/>
                    : <>
                        <i className="fas fa-times me-1"/>Close
                    </>
                }
            </button>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        token: state.user.token,
    }
}

export default connect(mapStateToProps)(Search)