import { getFirestore } from "firebase/firestore"
import { initializeApp } from 'firebase/app';

let firebaseConfig = {
  apiKey: "AIzaSyAFBjVDwiBkhOTK2CPWCMCEvC8GooVVRFI",
  authDomain: "stayconnectedtv-e3294.firebaseapp.com",
  projectId: "stayconnectedtv-e3294",
  storageBucket: "stayconnectedtv-e3294.appspot.com",
  messagingSenderId: "417485325898",
  appId: "1:417485325898:web:d632ded4fb4b5f8593c8b3",
  measurementId: "G-H2FG83Y618"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
