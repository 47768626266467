import React from 'react';
import HorizontalScroll from "../components/horizontalScroll";
import VideoBlock from "./videoBlock";
import {getValidToken, recentURL} from "../config/apis";
import {connect} from "react-redux";
import {login, setRecent} from "../store/reducers/userSlice";

const ContinueWatch = (props) => {
    React.useEffect(() => {
        getValidToken(props.token).then(token => {
            props.login(token)
            fetch(recentURL, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token['token']
                }
            }).then(resp => {
                if (resp.status === 200) {
                    resp.json().then((data) => {
                        props.setRecent(data)
                    })
                } else if (resp.status === 401) {
                    props.reset()
                }
            })
        })
    },[])
    return (
        <>
            {props.recent && props.recent.length >= 3 ?
                <HorizontalScroll className={'my-3'} title={"Continue Watching"}>
                    {
                        props.recent.map((item) => {
                            return <VideoBlock item={item} recent={true}/>
                        })
                    }
                </HorizontalScroll>
                :
                <></>
            }
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        token: state.user.token,
        recent: state.user.recent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: 'RESET_APP'}),
        login: (data) => dispatch(login(data)),
        setRecent: (data) => dispatch(setRecent(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContinueWatch);
