import React from "react";
import './footer.css'
import {Link} from "react-router-dom";
import Amex from '../assets/img/payments/amex.png'
import Discover from '../assets/img/payments/discover.png'
import Master from '../assets/img/payments/mastercard.png'
import Visa from '../assets/img/payments/visa.png'

export default function Footer(props) {
    React.useEffect(() => {
        const footer = document.createElement('footer');
        footer.className = 'oneView py-2';
        function para(text) {
            const p = document.createElement('p');
            p.textContent = text
            return p
        }
        document.body.appendChild(footer).append(para("Copyright © 2020 All Rights Reserved"), para("Stay Connected TV"));
        return () => {
            document.body.removeChild(footer);
        };
    }, [])

    return (
        <div>
            <div className={"footer"}>
                <Link to={'/contactUs'} className={"links"}>Contact Us</Link>
                <Link to={'/terms_and_conditions'} className={"links"}>Terms & Conditions</Link>
                <Link to={'/privacy_policy'} className={"links"}>Privacy Policy</Link>
                <Link to={'/faq'} className={"links"}>FAQ's</Link>
                <Link to={'/content_provider_sign_up'} className={"links"}>Content Provider</Link>
            </div>
            <footer className="oneView">
                <p className={'text-black-muted d-flex align-items-center'}>Cards Accepted: <img src={Amex} className={"mx-1"}/><img src={Discover} className={"me-1"}/><img
                    src={Master} className={"me-1"}/><img src={Visa} className={"me-1"}/></p>
            </footer>
        </div>
    )
}
