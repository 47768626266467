import React from 'react';
import { initList, login, setList } from "../store/reducers/userSlice";
import { connect } from "react-redux";
import HorizontalScroll from "../components/horizontalScroll";
import VideoBlock from "./videoBlock";

const ListWatch = (props) => {
    return (
        <div>
            {
                props.location.type === 'movies' ?
                    props.movies.map((category, i) => {
                        if (category.data.length > 0)
                            return <HorizontalScroll className={'my-3'} title={category['title']}>
                                {
                                    category.data.map((item) => {
                                        return <VideoBlock item={item} />
                                    })
                                }
                            </HorizontalScroll>
                    })
                    :
                    <></>}
            {
                props.location.type === 'series' ?
                    props.series.map((category, i) => {
                        if (category.data.length > 0)
                            return <HorizontalScroll className={'my-3'} title={category['title']} key={i}>
                                {
                                    category.data.map((item, index) => {
                                        return <VideoBlock item={item} key={index} />
                                    })
                                }
                            </HorizontalScroll>
                    })
                    :
                    <></>
            }
            {props.home.map((category, i) => {
                if (category.data.length > 0)
                    return <HorizontalScroll className={'my-3'} title={category['title']} key={i}>
                        {
                            category.data.map((item, index) => {
                                return <VideoBlock item={item} key={index} />
                            })
                        }
                    </HorizontalScroll>
            })
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
        home: state.masterData.home,
        movies: state.masterData.movies,
        series: state.masterData.series,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({ type: 'RESET_APP' }),
        login: (data) => dispatch(login(data)),
        setList: (data) => dispatch(setList(data)),
        initList: (data) => dispatch(initList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListWatch);