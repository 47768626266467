import React, {useState} from 'react'
import {isMobileOnly} from "react-device-detect";
import {Link, useHistory} from "react-router-dom";
import {forgotPasswordURL} from "../config/apis";
import AuthContainer from "./authContainer";

export default function EnterOTP(props) {
    React.useEffect(() => {
        document.title = props.title
        window.scrollTo(0, 0)
    }, [props.title])
    const [otp, setOTP] = useState('')
    const [feedback, setFeedback] = useState('')
    const [loading, setLoading] = useState(false)
    let history = useHistory();

    function verify(e) {
        e.preventDefault()
        if (otp === '') {
            return
        }
        setLoading(true)
        setTimeout(() => {
            fetch(forgotPasswordURL, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mail: window.location.search.split('=')[1],
                    otp: otp
                })
            }).then(response => {
                if (response['status'] === 401) {
                    setFeedback('Invalid OTP')
                } else {
                    history.push('/createNewPassword?' + otp + '=' + window.location.search.split('=')[1])
                }
            }).catch(() => {
                setFeedback('Network Error')
            }).finally(() => {
                setLoading(false)
            })
        }, 100)
    }

    return (
        <>
            <AuthContainer>
                <Link to={'/'}>
                    <button className="small theme mb-4">
                        <i className="fas fa-home me-1"/>Home
                    </button>
                </Link>
                <h3 className={'mb-1 fw-300'}>Enter OTP</h3>
                <h6 className={'mb-4 fw-200 ' + [isMobileOnly ? 'small' : '']}>Enter the OTP you recieved on your registered email address.</h6>
                <form action="#" onSubmit={verify}>
                    <input type="text" placeholder={'Enter OTP'} className={'br2'} onChange={(e) => setOTP(e.target.value)}/>
                    <h7 className={'mb-2 text-danger'}>{feedback}</h7>
                    <button className="stayConnectedTheme text-white w-100 mt-2" disabled={loading}>
                        {loading && <i
                            className="fa fa-circle-notch fa-spin"
                            style={{marginRight: "5px"}}
                        />}
                        Verify OTP
                    </button>
                </form>
            </AuthContainer>
        </>
    )
}