import React from 'react'
import {isMobileOnly} from "react-device-detect";

export default function ScrollTop() {
    let bottom, right;
    switch (isMobileOnly) {
        case true:
            bottom = 35
            right = 35
            break;
        default:
            bottom = 40
            right = 40
    }
    window.addEventListener("scroll", function () {
        let ScrollTopButton = document.getElementById('scrollTopButton')
        if (ScrollTopButton) {
            if(window.scrollY){
                ScrollTopButton.classList.remove('d-none')
            }
            else{
                ScrollTopButton.classList.add('d-none')
            }
        }
    })
    return (
        <button
            id={'scrollTopButton'}
            onClick={() => window.scrollTo({
                top: 0,
                behavior: "smooth"
            })}
            className="square circle d-none"
            style={{
                position: 'fixed',
                bottom: bottom,
                right: right,
                zIndex: 99
            }}
        >
            <i className="fas fa-arrow-up"/>
        </button>
    )
}