import React, {useState} from 'react'
import {Link} from "react-router-dom";
import {ReactComponent as GoogleSVG} from "../assets/svg/SVGgoogle.svg";
import {isMobileOnly} from "react-device-detect";
import {connect} from "react-redux";
import {GoogleSignInUrl, loginURL} from "../config/apis";
import {firstLogin} from "../store/reducers/userSlice";
import {getAuth, signInWithCustomToken, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import AuthContainer from "./authContainer";

function Login(props) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errText, setErrTxt] = useState(' ')
    const [loading, setLoading] = useState(false)

    function googleSignIn() {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                fetch('https://www.googleapis.com/userinfo/v2/me', {
                    headers: {Authorization: `Bearer ${token}`},
                }).then(r => {
                    r.json().then(data => {
                        fetch(GoogleSignInUrl, {
                            method: "POST",
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data)
                        }).then(resp => {
                            if (resp['status'] === 409) {
                                setErrTxt('Email is already in use')
                            } else if (resp['status'] === 200) {
                                resp.json().then((tokens) => {
                                    props.firstLogin(tokens)
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 100)
                                })
                            } else {
                                setErrTxt('Something went wrong!')
                            }
                        }).catch((err) => {
                            console.log(err)
                            setErrTxt('Network Error!')
                        }).finally(() => {
                            setLoading(false)
                        })
                    })
                });
            }).catch((error) => {
            console.log(error)
            setLoading(false)
            setErrTxt('Something went wrong!')
        });
    }

    function login() {
        if (username === '') {
            setErrTxt('Please enter a valid UserID and Password!')
            return;
        }
        let temp = username.split('@')
        temp[0] = temp[0].replace('.', '')
        temp = temp[0] + '@' + temp[1]
        setLoading(true)
        setTimeout(() => {
            fetch(loginURL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'username': temp.trim().toLowerCase(),
                    'password': password
                })
            }).then(response => {
                if (response['status'] === 401) {
                    setErrTxt('Incorrect Username or Password!')
                } else if (response['status'] === 200) {
                    response.json().then(data => {
                        props.firstLogin(data)
                        loginWithCustomToken(data['custom_token'])
                    })
                } else {
                    setErrTxt('Something went wrong!')
                }
            }).catch(() => {
                setErrTxt('Network Error!')
            }).finally(() => {
                setLoading(false)
            })
        }, 100)
    }

    function loginWithCustomToken(customToken) {
        const auth = getAuth();
        signInWithCustomToken(auth, customToken)
            .then((userCredential) => {
                window.location.reload()
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }

    return (
        <>
            <AuthContainer>
                <h3 className={'mb-1 fw-300'}>Welcome back</h3>
                <h6 className={'mb-2 fw-300 ' + [isMobileOnly ? 'small' : '']}>Please sign in to your account</h6>
                <form action="#">
                    <input type="email" placeholder={'Email'} className={'mb-2 br2'}
                           onChange={(e) => setUsername(e.target.value)}/>
                    <input type="password" placeholder={'Password'} className={'mb-2 br2'}
                           onChange={(e) => setPassword(e.target.value)}/>
                    <Link to={'/forgotPassword'} className={'text-muted fw-500 text-decoration-none'}>
                        Forgot Password
                    </Link><br/>
                    <h6 className={'mb-2 text-danger'}>{errText}</h6>
                    <button className="stayConnectedTheme text-white w-100 mt-2" onClick={login} disabled={loading}>
                        {loading && <i
                            className="fa fa-circle-notch fa-spin"
                            style={{marginRight: "5px"}}
                        />}
                        Login
                    </button>
                    <button type={"button"} className="w-100 mt-2 mb-4 d-flex align-items-center justify-content-center"
                            disabled={loading} onClick={googleSignIn}>
                        <GoogleSVG height={20} className={'me-1'}/>Sign In With Google
                    </button>
                    <div className={'text-center d-flex align-item-center justify-content-center small'}>
                        Don't have an account?
                        <Link to={'/signUp'}
                              className={'ms-1 text-initial text-decoration-none fw-500 text-themeStayConnected'}>SignUp</Link>
                    </div>
                    <div className={'text-center d-flex align-item-center justify-content-center small mt-2'}>
                        Are you a Content Provider?
                        <Link to={'/content_provider_sign_up'}
                              className={'ms-1 text-initial text-decoration-none fw-500 text-themeStayConnected'}>SignUp</Link>
                    </div>
                </form>
            </AuthContainer>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {firstLogin})(Login)
