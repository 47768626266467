import React from 'react';
import {isMobile} from "react-device-detect";
import './fullPlayer.css'
import {b64_to_utf8} from "../config/helper";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../config/firebase";
import moment from "moment";

const PremierPlayer = (props) => {
    const vidRef = React.createRef()
    const [player, setPlayer] = React.useState();
    const [start, setStart] = React.useState();
    const [url, setUrl] = React.useState(b64_to_utf8((props.location.search ? props.location.search : props.location.pathname).split("?data=")[1]).split(';')[0])
    React.useEffect(() => {
        if (window.Vimeo) {
            initPlayer()
        } else {
            setTimeout(() => {
                initPlayer()
            }, 200)
        }
        getDoc(doc(db, "premier", b64_to_utf8((props.location.search ? props.location.search : props.location.pathname).split("?data=")[1]).split(';')[1])).then(docRef => {
            setStart(moment(new Date(docRef.data().startAt.seconds * 1000)))
        })

    }, [])

    React.useEffect(() => {
        if (player && start) {
            player.setCurrentTime((moment() - start) / 1000)
            player.on('loaded', (data) => {
                player.setCurrentTime((moment() - start) / 1000)
            });
        }
    }, [player, start])

    const initPlayer = () => {
        setPlayer(new window.Vimeo.Player(vidRef.current));
    }
    return (
        <div className={isMobile ? "rotate-90" : ""}>
            <div
                onClick={props.history.goBack}
                className="d-flex-row text-white align-items-center justify-content-between"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 99,
                    cursor: 'pointer'
                }}
            >
                <i className="fas fa-arrow-left p-2"/>
                Back to Browse
            </div>
            <div className={'oneview br1'} id={"playerview"}>
                <iframe
                    ref={vidRef}
                    src={"https://player.vimeo.com/video/" + url + "?autoplay=true&controls=0"}
                    frameBorder="0"
                    allow="autoplay"
                    allowFullScreen
                    style={{height: '100%', width: '100%'}}
                    referrerPolicy="origin"
                />
            </div>
        </div>
    )
};

export default PremierPlayer;