import React from 'react';
import {getValidToken, myListURL} from "../config/apis";
import {connect} from "react-redux";
import {initList, login} from "../store/reducers/userSlice";
import VideoBlock from "./videoBlock";
import HorizontalScroll from "../components/horizontalScroll";

const MyList = (props) => {
    React.useEffect(() => {
        getValidToken(props.token).then(token => {
            props.login(token)
            fetch(myListURL + '?results=' + props.list.results + '&page=1', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token['token']
                },
            }).then(resp => {
                resp.json().then(data => {
                    props.initList(data)
                })
            })
        })
    }, [])
    return (
        <>
            {props.list && props.list.count >= 3 ?
                <HorizontalScroll className={'my-3'} title={"My List"}>
                    {
                        props.list.data.map((item, i) => {
                            return <VideoBlock item={item} myList={true} key={i}/>
                        })
                    }
                </HorizontalScroll>
                :
                <></>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
        home: state.masterData.home,
        list: state.user.list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: 'RESET_APP'}),
        login: (data) => dispatch(login(data)),
        initList: (data) => dispatch(initList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyList);