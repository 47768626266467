import {createSlice} from '@reduxjs/toolkit'
import {userState} from '../initialState';

const userSlice = createSlice({
    name: "user",
    initialState: userState,
    reducers: {
        login: (state, action) => {
            if (state.token)
                state.token = action.payload
        },
        firstLogin: (state, action) => {
            state.token = action.payload
        },
        basicDet: (state, action) => {
            state.basicDetails = action.payload
        },
        initList: (state, action) => {
            state.list.data = action.payload['results']
            state.list.count = action.payload['count']
            state.list.page = 2
        },
        setList: (state, action) => {
            state.list.data = state.list.data.concat(action.payload['results'])
            state.list.count = action.payload['count']
            state.list.page += 1
        },
        addToList: (state, action) => {
            if (state.list.data.length % state.list.results === 0) {
                state.list.data.pop()
            }
            state.list.data.splice(0, 0, action.payload)
            state.list.count += 1
        },
        removeFromList: (state, action) => {
            if (state.list.data.length % state.list.results === 1) {
                state.list.page -= 1
            }
            for (let i in state.list.data) {
                if (state.list.data[i].movie && action.payload[0] === 'movie') {
                    if (state.list.data[i].movie['id'] === action.payload[1]) {
                        state.list.data.splice(i, 1)
                        state.list.count -= 1
                        break
                    }
                } else if (action.payload[0] !== 'movie') {
                    if (state.list.data[i].series['id'] === action.payload[1]) {
                        state.list.data.splice(i, 1)
                        state.list.count -= 1
                        break
                    }
                }
            }
        },
        setRecent: (state, action) => {
            state.recent = action.payload
        },
        setAppliedCode: (state, action) => {
            state.basicDetails.appliedCode = action.payload
        }
    }
});
export const {login, basicDet, initList, setList, addToList, removeFromList, setRecent, setAppliedCode, firstLogin} = userSlice.actions
export default userSlice.reducer