import React from 'react'
import './modal.css'

export default function Modal(props) {
    const escapeKey = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };
    React.useEffect(() => {
        document.body.addEventListener("keydown", escapeKey);
        return function cleanup() {
            document.body.removeEventListener("keydown", escapeKey);
        };
    }, []);
    React.useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
    }, [])
    return (
        <div className={'modal'} onClick={props.onClose}>
            <div className={props.big ? "modal-content-2" : "modal-content"} onClick={e => e.stopPropagation()}>
                {props.title
                    ? <div className={"modal-title p-2"}>
                        <p className="h4">{props.title}</p>
                    </div>
                    : <></>
                }
                <div className={`modal-body ${props.title ? "pt-0" : ""} ` + [props.className]}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}