import React from 'react'
import './profile.css'
import {isMobileOnly} from "react-device-detect";
import {Link, Switch, Route, useLocation, useRouteMatch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import MyWatchParty from "./watchParty";

const User = React.lazy(() => import('./user'))
const ManageList = React.lazy(() => import('./manageList'))
const Wallet = React.lazy(() => import('./wallet/wallet'))

const ProfileLinks = [
    {
        icon: '',
        label: 'Profile',
        link: '/user'
    },
    {
        icon: '',
        label: 'Manage Lists',
        link: '/manageList'
    },
    {
        icon: '',
        label: 'Wallet',
        link: '/wallet'
    },
    {
        icon: '',
        label: 'Watch Party',
        link: '/watch_party'
    }
]

const Loading = <div className={'d-center'} style={{height: '30vh'}}>Loading</div>

function Profile(props) {
    React.useEffect(() => {
        document.title = props.title
        window.scrollTo(0, 0)
    }, [props.title])
    const {path, url} = useRouteMatch();
    const Location = useLocation();
    return (
        <div>
            <header className={'profile'}>
                <ul className="list-style-none d-flex justify-content-center h-100 flex-wrap">
                    {ProfileLinks.map((item, index) => (
                        <li key={index} className={'mx-2 px-2' + [isMobileOnly ? ' py-2' : ''] + [Location.pathname === url + item.link ? ' active' : '']}>
                            <Link to={url + item.link} className={'d-center text-center text-initial text-decoration-none h-100' + [isMobileOnly ? ' small' : '']}>
                                {item.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </header>
            <React.Suspense fallback={Loading}>
                <Switch>
                    <Route
                        exact
                        path={path + '/user'}
                        render={props => (<User component={User} title={'User | Stay Connected TV'} {...props}/>)}
                    />
                    <Route
                        exact
                        path={path + '/manageList'}
                        render={props => (<ManageList component={ManageList} title={'Manage List | Stay Connected TV'} {...props}/>)}
                    />
                    <Route
                        exact
                        path={path + '/wallet'}
                        render={props => (<Wallet component={Wallet} title={'My Wallet | Stay Connected TV'} {...props}/>)}
                    />
                    <Route
                        exact
                        path={path + "/watch_party"}
                        render={props => (<MyWatchParty component={MyWatchParty} title={"Watch Party | Stay Connected TV"} {...props} />)}
                    />
                    {
                        props.token ?
                            <Redirect to={url + '/user'}/>
                            :
                            <Redirect to={'/'}/>
                    }
                </Switch>
            </React.Suspense>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.user.token,
    }
}

export default connect(mapStateToProps)(Profile)
