import React from 'react';
import {getValidToken, recentURL} from "../config/apis";
import {isMobile} from "react-device-detect";
import {connect} from "react-redux";
import {login, setRecent} from "../store/reducers/userSlice";
import './fullPlayer.css'

class FullPlayer extends React.Component {
    vidRef = React.createRef()
    state = {
        progress: 0,
        start: 0,
        time: 0,
        duration: 0,
        last: "pause",
        data: {}
    }
    player = ''

    constructor(props) {
        super(props)
        let data = atob(props.location.search.replace("?data=", "")).split(';')
        this.state['data'] = {
            url: data[0],
            progress: data[1],
            type: data[2],
            id: data[3]
        }
        if (data.length > 4) {
            this.state['data']['epId'] = data[4]
        }
    }

    componentDidMount() {
        if (window.Vimeo) {
            this.setPlayer()
        } else {
            setTimeout(() => {
                this.setPlayer()
            }, 200)
        }
    }

    setPlayer = () => {
        this.player = new window.Vimeo.Player(this.vidRef.current);
        this.player.on('timeupdate', (data) => {
            this.setState({
                progress: data['percent'] * 100
            })
        });
        this.player.on('play', (data) => {
            this.setState({
                last: "play",
                duration: data['duration'],
                start: data['seconds']
            })
        });
        this.player.on('pause', (data) => {
            this.setState({
                last: "pause",
                time: this.state.time + data['seconds'] - this.state.start,
                start: data['seconds']
            })
        });
        this.player.on('ended', (data) => {
            this.setState({
                last: "ended",
                duration: this.state.time + data['seconds'] - this.state.start,
                start: data['seconds']
            })
        });
        this.player.on('seeked', (data) => {
            if (this.state.last === 'play') {
                this.setState({
                    time: this.state.time + (data['duration'] * parseFloat(this.state.progress)) - this.state.start
                })
            }
            this.setState({
                start: data['seconds']
            })
        });
        this.player.getDuration().then((duration) => {
            this.setState({
                duration: duration
            })
            this.player.setCurrentTime(duration * parseInt(this.state.data.progress) / 100)
        });
    }

    componentWillUnmount() {
        if (this.state.progress !== 0) {
            getValidToken(this.props.token).then(token => {
                this.props.login(token)
                let time = this.state.time
                if (this.state.last === 'play') {
                    time = time + (this.state.progress * this.state.duration) - this.state.start
                }
                let h = parseInt(time / 3600)
                let m = parseInt(time / 60) - h * 60
                let s = parseInt(time % 60)
                fetch(recentURL, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token['token']
                    },
                    body: JSON.stringify({
                        type: this.state.data.type,
                        id: this.state.data.id,
                        epId: this.state.data.epId,
                        progress: this.state.progress,
                        time: h + ':' + m + ':' + s
                    })
                }).then(resp => {


                    if (resp.status === 200) {
                        resp.json().then((data) => {
                            this.props.setRecent(data)
                        })
                    } else if (resp.status === 401) {
                        this.props.reset()
                    }
                })
            })
        }
    }

    render() {
        return (
            <div className={isMobile ? "rotate-90" : ""}>
                <div
                    onClick={this.props.history.goBack}
                    className="d-flex-row text-white align-items-center justify-content-between"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 99,
                        cursor: 'pointer'
                    }}
                >
                    <i className="fas fa-arrow-left p-2"/>
                    Back to Browse
                </div>
                <div className={'oneview br1'}>
                    <iframe
                        ref={this.vidRef}
                        src={"https://player.vimeo.com/video/" + this.state.data.url + "?autoplay=1"}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{height: '100%', width: '100%'}}
                        referrerPolicy="origin"
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: 'RESET_APP'}),
        login: (data) => dispatch(login(data)),
        setRecent: (data) => dispatch(setRecent(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullPlayer);