import React from 'react';
import {isMobile} from "react-device-detect";
import {login} from "../../store/reducers/userSlice";
import {connect} from "react-redux";
import {b64_to_utf8} from "../../config/helper";
import {db} from "../../config/firebase";
import {getDoc, setDoc, updateDoc, doc, onSnapshot} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getUrl, getValidToken, watchPartyInviteUrl} from "../../config/apis";
import moment from "moment";
import Player from '@vimeo/player';
import PartyMessages from "./partyMessages";

const PartyPlayer = (props) => {
    let sessionID = props.match.params.sessionID.split("?")[0]
    const [player, setPlayer] = React.useState();
    const [url, setUrl] = React.useState()
    const [isHost, setHost] = React.useState(null)
    const [participants, setParticipants] = React.useState([])
    const [messages, setMessages] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        let host = ''
        const queryString = window.location.search ? window.location.search : ("?" + window.location.pathname.split("?")[1]);
        let urlParams = new URLSearchParams(queryString);
        if (urlParams.get('data')) {
            setUrl(b64_to_utf8(urlParams.get('data')))
        } else {
            let payload = b64_to_utf8(urlParams.get('payload')).split(";")
            getValidToken(props.token).then(token => {
                props.login(token)
                fetch(getUrl, {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token['token']
                    },
                    body: JSON.stringify({
                        isMovie: payload[1] === "true",
                        id: payload[0]
                    })
                }).then(res => {
                    if (res.status === 401) {
                        props.reset()
                    } else if (res.status === 200) {
                        res.json().then(data => {
                            setUrl(data['url'])
                        })
                    }
                })
            })
        }
        setTimeout(() => {
            getDoc(doc(db, "watchPartyInvites", sessionID)).then(ref => {
                setParticipants(ref.data().invites)
                host = true
                setHost(true)
            }).catch(err => {
                if (urlParams.get('data')) {
                    props.history.goBack();
                }
                host = false
                setHost(false)
            })
        }, 100)
        return () => {
            // if (host)
                // destroyWatchParty(sessionID)
        }
    }, [])

    React.useEffect(() => {
        if (url && isHost !== null) {
            setPlayer(new Player('partyPlayer', {
                id: "https://player.vimeo.com/video/" + url + "?autoplay=1" + (isHost ? "" : "&controls=0"),
                controls: isHost,
                height: window.innerHeight,
                width: window.innerWidth,
                autoplay: true,
                keyboard: false,
                loop: false
            }));
            let intv = setInterval(() => {
                setDoc(doc(db, "userActivity", getAuth().currentUser.uid), {
                    lastActive: new Date(),
                    sessionID: sessionID
                })
            }, 60000)
            return () => clearInterval(intv)
        }
    }, [url, isHost])

    React.useEffect(() => {
        if (player && isHost !== null) {
            if (isHost) {
                setDoc(doc(db, "watchParty", sessionID), {
                    hostedBy: getAuth().currentUser.uid,
                    messages: [],
                    participants: participants.map(participant => participant.uid),
                    status: {
                        loading: true,
                        paused: false,
                        playing: false,
                    }
                })
                player.on('play', (data) => createPlayback(data, "playing"));
                player.on('pause', (data) => createPlayback(data, "paused"));
                player.on('seeked', (data) => createPlayback(data, ""));
            } else {
                const unsub = onSnapshot(doc(db, "watchParty", sessionID), (docRef) => {
                    updatePlayback(docRef)
                }, err => props.history.goBack());
                player.on('loaded', (data) => {
                    getDoc(doc(db, "watchParty", sessionID)).then((docRef) => {
                        updatePlayback(docRef)
                    }).catch(err => props.history.goBack());
                })
                player.on('pause', (data) => {
                    getDoc(doc(db, "watchParty", sessionID)).then((docRef) => {
                        let data2 = docRef.data()
                        if (data2.status["playing"]) {
                            player.play()
                        }
                        if (data2.lastKnown) {
                            let diff = (moment() - moment(new Date(data2.lastKnown.at.seconds * 1000))) / 1000
                            player.setCurrentTime(data2.lastKnown.seconds + diff)
                        }
                    }).catch(err => props.history.goBack());
                })
                return unsub
            }
        }
    }, [player, isHost])

    const updatePlayback = (docRef) => {
        let data = docRef.data()
        setMessages(data.messages)
        if (data.lastKnown) {
            setLoading(false)
            let diff = (moment() - moment(new Date(data.lastKnown.at.seconds * 1000))) / 1000
            player.setCurrentTime(data.lastKnown.seconds + diff)
            if (data.status["loading"] || data.status["paused"]) {
                player.pause()
            }
            if (data.status["playing"]) {
                player.play()
            }
        } else {
            player.pause()
            setLoading(true)
        }
    }

    const createPlayback = (data, status) => {
        let payload = {
            lastKnown: {
                at: new Date(),
                seconds: data.seconds
            }
        }
        if (status) {
            payload["status"] = {
                loading: status === "loading",
                paused: status === "paused",
                playing: status === "playing",
            }
        }
        updateDoc(doc(db, "watchParty", sessionID), payload)
    }

    const setFullscreen = () => {
        player.requestFullscreen().then(resp => {
            console.log(resp)
        }).catch(err => {
            console.log(err)
        })
    }

    function destroyWatchParty(sID) {
        getValidToken(props.token).then(token => {
            props.login(token)
            fetch(watchPartyInviteUrl, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token['token']
                },
                body: JSON.stringify({sessionID: sID})
            }).then(res => {
                if (res.status === 401) {
                    props.reset()
                }
                if (res.status === 200) {
                }
            })
        })
    }

    return (
        <div className={isMobile ? "rotate-90" : ""}>
            {
                isHost || <div style={{zIndex: 100, height: '100vh', width: '100vw', position: 'fixed'}}/>
            }
            <div
                onClick={props.history.goBack}
                className="d-flex-row text-white align-items-center justify-content-start"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 100,
                    cursor: 'pointer'
                }}
            >
                <i className="fas fa-arrow-left p-2"/>
                {isHost ? "End Watch Party" : "Back to Browse"}
            </div>
            <div className={'oneview br1'} id={"partyPlayer"}>
            </div>
            <div style={{
                position: 'fixed',
                top: "50%",
                left: "50%",
                transform: 'translate(-50%,-50%)',
                zIndex: 100,
            }}>
                {
                    loading && <h3>Waiting for Host to start the Party!</h3>
                }
            </div>
            <div

                className="d-flex text-white align-items-center justify-content-end"
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    zIndex: 100,
                    cursor: 'pointer'
                }}
            >
                {
                    isHost ||
                    <div onClick={setFullscreen}>
                        <i className="fas fa-expand fa-lg p-2"/>
                    </div>
                }
                <PartyMessages messages={messages} isHost={isHost} sessionID={sessionID} participants={participants}/>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        token: state.user.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: 'RESET_APP'}),
        login: (data) => dispatch(login(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartyPlayer);