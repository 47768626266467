import React from 'react';
import {Data} from "./data_faq";
import {isMobile} from "react-device-detect";

export default function FAQs(props) {
    React.useEffect(() => {
        document.title = props.title;
        window.scrollTo(0, 0);
    })
    return (
        <section className={'container my-5'}>
            <div className={"header-margin"}>
                <h1 className={'text-transform-uppercase'}>FAQ's</h1>
                {Data.map((item, index) => (
                    <div className={index === 0 ? 'my-5' : 'mb-5'} key={index}>
                        {isMobile
                            ? <h5 className={'mb-1'}>Q. {item.faqQuestion}</h5>
                            : <h3 className={'mb-1'}>Q. {item.faqQuestion}</h3>
                        }
                        <p>{item.faqAnswer.split("\n").map((item, i) => (
                            <div className={'mb-1'} key={i}>
                                <p className={'first-letter-capital'}>{item}</p>
                            </div>
                        ))}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}
