import React from 'react'
import './currentPremiere2.css';
import {isMobile} from "react-device-detect";
import Slider from 'infinite-react-carousel';
import {getLocalDate, utf8_to_b64} from '../config/helper';
import {baseUrl} from "../config/apis";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

function CurrentPremiere(props) {
    const upcomingData = props.data;
    const settings = {
        autoplay: true,
        autoplaySpeed: 6000,
        centerMode: true,
        centerPadding: 100,
        className: 'carousel',
        initialSlide: 1,
        overScan: 1,
        shift: 50,
        virtualList: true,
    };
    return (
        <>
            <Slider {...settings}>
                {upcomingData.map((item, index) => {
                    return (
                        <div className="carouselItem" key={index}>
                            <img src={baseUrl + item.still} alt={item.name} className={'carouselItemThumbnail ' + [isMobile ? 'br1' : 'br2']}/>
                            <div className={"carouselItemDataPanel d-flex-column justify-content-between" + [isMobile ? '' : ' p-3']}>
                                <button className={'d-flex align-items-center small' + [isMobile ? ' theme' : ' status']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-1 text-success" viewBox="0 0 16 16">
                                        <circle cx="8" cy="8" r="8"/>
                                    </svg>
                                    On Going
                                </button>
                                <div className={isMobile ? 'd-flex-column' : "d-flex align-items-end justify-content-between"}>
                                    <div className="carouselItemData">
                                        <p>Premiered On: {getLocalDate(item.premiereOn)}</p>
                                        <p className={'mb-1'}>
                                            <small>{(item.genre ? item.genre : item.series.genre).map(item => item.name).join(". ")}</small>
                                        </p>
                                        {!item.order //=== "movie"
                                            ? isMobile ? <h5 className={'h5'}>{item.name}</h5> : <h1 className={'h1'}>{item.name}</h1>
                                            : isMobile
                                                ? <>
                                                    <h5 className={'h5'}>{item.series.name}</h5>
                                                    <h3 className={'h3'}>{item.name}</h3>
                                                </>
                                                : <>
                                                    <h3 className={'h3'}>{item.series.name}</h3>
                                                    <h1 className={'h1'}>{item.name}</h1>
                                                </>
                                        }
                                        <ul className={'list-style-none d-flex align-items-center my-1'}>
                                            <li className="me-1">Age: {!item.order ? item.rating : item.series.rating}</li>
                                            <li className="me-1">Year: {!item.order ? item.year : item.series.year}</li>
                                            <li className="me-1">Duration: {item.duration.split(":")[0] + "Hr " + item.duration.split(":")[1] + "Min"}</li>
                                        </ul>
                                        <p className={'line2 text-muted'}>{item.description}</p>
                                    </div>
                                    {
                                        props.basicDetails.subscription.name
                                            ?
                                            <Link className={"text-decoration-none"} to={{
                                                pathname: '/premier/' + (item.name ? item.name.split(' ').join('_') : '') + '?data=' + utf8_to_b64(item.url + ";" + (item.order ? "E" : "M") + item.id)
                                            }}>
                                                <button className={"d-flex align-items-center" + [isMobile ? ' small mt-2' : '']}>
                                                    Join Now
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-1" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd"
                                                              d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"/>
                                                    </svg>
                                                </button>
                                            </Link>
                                            :
                                            <Link to={'/profile'}>
                                                <button className={isMobile ? ' small mt-2' : ''}>
                                                    Get Subscribed
                                                </button>
                                            </Link>
                                    }

                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </>
    )
}

const mapStateToProps = state =>{
    return {
        basicDetails: state.user.basicDetails,
    }
}

export default connect(mapStateToProps)(CurrentPremiere);
