export const Data = [
    {
        faqQuestion: `What is Stay Connected TV?`,
        faqAnswer: `Stay Connected tv was created by veteran indie filmmaker for the purpose of giving viewers fresh content. Stay Connected tv is streaming platform that offers tv series, documentaries, short films, music, culture and lifestyle shows and feature films. All of the content shown on Stay Connected tv is made outside of the studio system and all the profits shared with our filmmakers.`
    },
    {
        faqQuestion: `Our Membership Plans`,
        faqAnswer: `Stay Connected tv offers several membership levels and each level offers a way for indie film lovers to support their favorite filmmaker. Our low subscription rates make it a no brainer for you to give monthly support to filmmakers and the more you watch, the more they make. Choose a monthly, 3- Month or yearly plan.`
    },
    {
        faqQuestion: `How to Watch!`,
        faqAnswer: `You can watch your favorite Stay Connected tv content on any mobile device. We’re compatible with both Android and iOS devices. We can also be watched on your desktop or via our *Roku App (*coming soon).`
    },
    {
        faqQuestion: `Earn While you Watch!`,
        faqAnswer: `With our unique and proprietary referral system, each person you invite using your unique referral code (provided to you in your profile area) will receive a 10% discount on their subscription. You will in turn receive that 10% savings in your personal wallet. When your wallet reaches $100, you can request that money be sent to you via check, PayPal or Bank Wire. BUT THAT’S NOT IT!!! When your friends (that have subscribed using your referral code) refer their friends, your friends will also earn 10% from each referral, but YOU will also earn 5% from each of your friend’s referral!`
    },
    {
        faqQuestion: `What’s on Stay Connected TV?`,
        faqAnswer: `We’ve curated our content from the best and most talented filmmakers of across the globe. We want to be the #1 source for great content that focus on people of color and or created by people of color.  When you come to Stay Connected TV, you will find compelling, entertaining and inspiring content, don’t waste any more time, start watching now!`
    },
    {
        faqQuestion: `Preview Content`,
        faqAnswer: `With any new sign up you can preview our free content before subscribing to the service.  Once you’re ready to start earning money from our referral program and watching amazing content, pick the subscription package that is right for you. If you choose to cancel your subscription, you will have access to the service until the end of the billing cycle on all devices.`
    },
    {
        faqQuestion: `Get Started!`,
        faqAnswer: `We have several ways for you to subscribe, you can sign up via, credit card powered by Authorize.net or Bank Transfer. Sign up today.`
    }
]
