import React from 'react'
import './horizontalScroll.css'

export default function HorizontalScroll(props) {
    const navRef = React.createRef();

    function handleNav(direction) {
        if (direction === 'left') {
            if (navRef.current) {
                navRef.current.scrollLeft -= 500
            }
        } else {
            if (navRef.current) {
                navRef.current.scrollLeft += 500
            }
        }
    }

    return (
        <div className={props.className}>
            <h3 className={'mb-2 mx-5'}>{props.title}</h3>
            <div className='horizontalContainer'>
                <button className={'horizontalLeftAction'} onClick={() => handleNav('left')}><i className="fa fa-lg fa-chevron-left" /></button>
                <div className={'horizontalItems hide-scrollbar'} ref={navRef}>
                    {props.children}
                </div>
                <button className={'horizontalRightAction'} onClick={() => handleNav('right')}><i className="fa fa-lg fa-chevron-right" /></button>
            </div>
        </div>
    )
}