import React, {useEffect, useState} from "react";
import './itemDetails.css';
import {isMobile, isMobileOnly} from "react-device-detect";
import Player from "../player";
import {connect} from "react-redux";
import {baseUrl, getValidToken, myListURL, progressURL, recentURL} from "../config/apis";
import {addToList, login, removeFromList, setRecent} from "../store/reducers/userSlice";
import {markAddedToList, markRemovedFromList} from "../store/reducers/masterDataSlice";
import {Link, useHistory} from "react-router-dom";

function Season(props) {
    return (
        <section className={props.className}>
            {props.children}
        </section>
    )
}

function Episode(props) {
    return (
        <div className={'bg-themeMuted series-episodes cursor p-2 br2 mt-2 mb-1'}>
            {props.url
                ? <Link
                    to={{
                        pathname: '/play/' + (props.episodeName ? props.episodeName.split(' ').join('_') : '') + '?data=' + btoa(props.url + ';' + (props.progress ? parseInt(props.progress) : 0) + ';series;' + props.dataId + ';' + props.epId)
                    }}
                    className="episode-thumbnail br1 position-relative text-white"
                >
                    <img src={props.episodeThumbnail} alt="Thumbnail" className={'br1'}/>
                    <div className="playerIcon d-center br1">
                        <i className="fas fa-play-circle fa-2x"/>
                    </div>
                </Link>
                :
                <>
                    <Link to={"/profile"} className="episode-thumbnail br1 position-relative text-white">
                        <img src={props.episodeThumbnail} alt="Thumbnail" className={'br1'}/>
                        <div className="playerIcon d-center br1">
                            <i className="fas fa-lock fa-2x"/>
                        </div>
                    </Link>
                </>
            }

            <div className="episode-details">
                <div>
                    <h5 className={'mb-1'}>E{props.episodeNumber}. {props.episodeName}</h5>
                    <p className={isMobile ? 'line2 small' : 'line3'}>{props.episodeDescription}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <p><i className="fas fa-clock me-1"/>{props.episodeDuration}</p>
                    {
                        props.url &&
                        <Link
                            to={`/watch_party_invite?data=${btoa(props.epId + ";false;" + props.url)}`}
                            className={"text-decoration-none text-primary " + [isMobile ? "h6" : "h5"]}
                        >
                            Watch Party
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

function ItemData(props) {
    const [data, setData] = useState({
        duration: ':',
        genre: [],
        seasons: []
    })
    const [isMovie, setIsMovie] = useState(true)
    const [listDisabled, setListDisabled] = useState(false)
    const [seasons, setSeasons] = useState([])
    const [currSeasons, setCurrSeasons] = useState(1)
    const [trailer, setTrailer] = useState()
    const [episodes, setEpisodes] = useState([])
    const [progress, setProgress] = useState({})
    const history = useHistory()
    useEffect(() => {
        setData(props.item)
        if (props.type !== 'movie') {
            let SeasonList = [];
            for (let i in props.item.seasons) {
                SeasonList.push(props.item.seasons[i].seasonNumber)
            }
            SeasonList.sort()
            setCurrSeasons(SeasonList[0])
            setSeasons(SeasonList)
            let index = props.item.seasons.findIndex(obj => obj.seasonNumber === SeasonList[0])
            let epi = JSON.parse(JSON.stringify(props.item.seasons[index].episode))
            setTrailer(props.item.seasons[index].trailerUrl)
            epi.sort(function (a, b) {
                return parseInt(a.order) - parseInt(b.order)
            })
            setEpisodes(epi)
        } else {
            if (props.token)
                getValidToken(props.token).then(token => {
                    props.login(token)
                    fetch(progressURL + `?id=${props.item.id}&type=movie`, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token['token']
                        },
                    }).then(resp => {
                        if (resp.status === 401) {
                            props.reset();
                        } else if (resp.status === 200) {
                            resp.json().then((data) => {
                                setProgress(data['progress'])
                            })
                        }
                    })
                })
        }
        setIsMovie(props.type === 'movie')
    }, [props])

    useEffect(() => {
        if (props.token)
            for (let i in episodes) {
                getValidToken(props.token).then(token => {
                    props.login(token)
                    fetch(progressURL + `?id=${episodes[i].id}&type=series`, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token['token']
                        },
                    }).then(resp => {
                        if (resp.status === 401) {
                            props.reset();
                        } else if (resp.status === 200) {
                            resp.json().then((data) => {
                                let temp = JSON.parse(JSON.stringify(progress))
                                temp[episodes[i].id] = data['progress']
                                setProgress(temp)
                            })
                        }
                    })
                })
            }
    }, [episodes])
    const selectSeason = (i) => {
        setCurrSeasons(seasons[i])
        let epi = JSON.parse(JSON.stringify(props.item.seasons.find(obj => obj.seasonNumber === seasons[i]).episode))
        epi.sort(function (a, b) {
            return parseInt(a.order) - parseInt(b.order)
        })
        setEpisodes(epi)
        setTrailer(props.item.seasons.find(obj => obj.seasonNumber === seasons[i]).trailerUrl)
    }

    function toggleList() {
        setListDisabled(true)
        getValidToken(props.token).then(token => {
            props.login(token)
            fetch(myListURL, {
                method: data.inList ? 'DELETE' : 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token['token']
                },
                body: JSON.stringify({
                    id: data.id,
                    type: props.type
                })
            }).then(resp => {
                if (resp.status === 401) {
                    props.reset();
                } else if (resp.status === 200) {
                    const temp = JSON.parse(JSON.stringify(data))
                    temp.inList = !temp.inList;
                    setData(temp)
                    if (temp.inList) {
                        resp.json().then(data => {
                            if (temp.seasons) {
                                props.addToList({series: temp, id: data['id']})
                                props.markAddedToList(['series', temp.id])
                            } else {
                                props.addToList({movie: temp, id: data['id']})
                                props.markAddedToList(['movie', temp.id])
                            }
                        })
                    } else {
                        if (temp.seasons) {
                            props.removeFromList(['series', data.id])
                            props.markRemovedFromList(['series', data.id])
                        } else {
                            props.removeFromList(['movie', data.id])
                            props.markRemovedFromList(['movie', data.id])
                        }
                    }
                    getValidToken(props.token).then(token => {
                        props.login(token)
                        fetch(recentURL, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token['token']
                            }
                        }).then(resp => {
                            if (resp.status === 200) {
                                resp.json().then((data) => {
                                    props.setRecent(data)
                                })
                            } else if (resp.status === 401) {
                                props.reset()
                            }
                        }).finally(() => {
                            setListDisabled(false)
                        })
                    })
                }
            })
        })
    }

    React.useEffect(() => {
        document.title = props.title
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className={'container my-5'}>
                <div className={"header-margin"}>
                    <div
                        onClick={history.goBack}
                        className="text-white"
                        style={{
                            cursor: 'pointer',
                            width: "fit-content"
                        }}
                    >
                        <i className="fas fa-arrow-left p-2"/>
                        Back to Browse
                    </div>
                </div>
                <Player url={isMovie ? data.trailerUrl : trailer}/>
                {isMobile
                    ? <h3 className={'text-transform-capitalize my-1'}>{data.name}</h3>
                    : <h1 className={'text-transform-capitalize my-1'}>{data.name}</h1>
                }
                <div className={"d-flex align-items-center mb-2"}>
                    <span className={'me-2 br1 bg-themeMuted p-1'}>{data.rating}</span>
                    <span className={'me-2'}>{data.year}</span>
                    <span className={'me-2'}>{data.duration ? data.duration.split(':')[0] + 'h ' + data.duration.split(':')[1] + 'm' : seasons.length + ' Seasons'}</span>
                </div>
                <div className="description">
                    <p className={isMobile && ' small'}>{data.description}</p>
                </div>
            </section>
            {
                isMovie ?
                    <>
                        {
                            data.url ?
                                <section className={'container my-5 d-flex'}>
                                    <Link to={{
                                        pathname: '/play/' + (data.name ? data.name.split(' ').join('_') : '') + '?data=' + btoa(data.url + ';' + (progress ? parseInt(progress) : 0) + ';movie;' + data.id)
                                    }}>
                                        <button className={"me-2"}>
                                            <i className="fas fa-play me-1"/>Play
                                        </button>
                                    </Link>
                                    <button className={'bg-transparentMuted ' + [data.inList ? 'text-success' : 'text-white']} onClick={toggleList} disabled={listDisabled}>
                                        <i className={"fas me-1 " + [data.inList ? 'fa-check' : 'fa-list']}/>Watch Later
                                    </button>
                                    <Link to={`/watch_party_invite?data=${btoa(data.id + ";true;" + data.url)}`}>
                                        <button className={"ms-2"}>Watch Party</button>
                                    </Link>
                                </section>
                                :
                                <section className={'container my-5 d-flex'}>
                                    <Link to={'/profile'}>
                                        <button className={"me-2"}>
                                            Get Subscribed
                                        </button>
                                    </Link>
                                </section>
                        }
                        <section className={'container my-5'}>
                            <h7 className={"text-whiteMutedDark my-2"}>Director: <span
                                className={"text-white"}>{data.director}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Cast: <span
                                className={"text-white"}>{data.cast}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Writer: <span
                                className={"text-white"}>{data.writer}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Genres: <span
                                className={"text-white"}>{data.genre.map((genre) => genre.name).join(', ')}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Maturity Rating: <span
                                className={"text-white"}>{data.rating}, {data.ratingDescription}</span></h7>
                        </section>
                    </>
                    :
                    <>
                        <section className={'container my-5'}>
                            <h7 className={"text-whiteMutedDark my-2"}>Director: <span
                                className={"text-white"}>{data.director}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Cast: <span
                                className={"text-white"}>{data.cast}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Writer: <span
                                className={"text-white"}>{data.writer}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Genres: <span
                                className={"text-white"}>{data.genre.map((genre) => genre.name).join(', ')}</span></h7>
                            <br/>
                            <h7 className={"text-whiteMutedDark my-2"}>Maturity Rating: <span
                                className={"text-white"}>{data.rating}, {data.ratingDescription}</span></h7>
                        </section>
                        <section className={'series-data my-5' + [isMobile ? '' : ' container']}>
                            <div className="series-seasons">
                                <header className={'season' + [isMobile ? '' : ' br1']}>
                                    <ul className={'list-style-none d-flex h-100' + [isMobileOnly ? ' overflowX' : '']}>
                                        {seasons.map((item, index) => (
                                            <li className={'mx-2 px-2' + [currSeasons === item ? ' active' : '']} key={index}>
                                                <div className={'d-center text-center text-initial text-decoration-none h-100'} onClick={() => selectSeason(index)}>
                                                    Season {item}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </header>
                                <React.Suspense fallback={<div className={'d-center'} style={{height: '30vh'}}>Loading...</div>}>
                                    <Season className={'my-2 ' + [isMobile ? 'container' : '']}>
                                        {episodes.map((item, index) => (
                                            <Episode
                                                token={props.token}
                                                key={index}
                                                url={item.url}
                                                episodeNumber={item.order}
                                                progress={progress[item.id.toString()] ? parseInt(progress[item.id.toString()]) : 0}
                                                dataId={data.id}
                                                epId={item.id}
                                                episodeName={item.name}
                                                episodeThumbnail={baseUrl + item.still}
                                                episodeDescription={item.description}
                                                episodeDuration={(item.duration.split(':')[0] !== '00' ? item.duration.split(':')[0] + 'h ' : '') + item.duration.split(':')[1] + 'm'}
                                            />
                                        ))}
                                    </Season>
                                </React.Suspense>

                            </div>
                        </section>
                    </>
            }

        </>
    )
}

const mapStateToProps = (state) => {
    let item;
    let id = window.location.search.split('=')[2].replace("x", '').replace("y", '');
    let type = window.location.search.split('&')[0].split('=')[1]
    if (window.location.search.endsWith('x')) {
        item = state.user.list.data.find(itm => {
            if (type === 'movie') {
                if (itm.movie !== null) {
                    return itm.movie.id.toString() === id
                }
            } else {
                if (itm.series !== null) {
                    return itm.series.id.toString() === id
                }
            }
            return false
        })
        if (type === 'movie') {
            item = item.movie
        } else {
            item = item.series
        }
    } else if (window.location.search.endsWith('y')) {
        item = state.user.recent.find(itm => {
            if (type === 'movie') {
                if (itm.movie !== null) {
                    return itm.movie.id.toString() === id
                }
            } else {
                if (itm.series !== null) {
                    return itm.series.id.toString() === id
                }
            }
            return false
        })
        if (type === 'movie') {
            item = item.movie
        } else {
            item = item.series
        }
    } else {
        for (let i in state.masterData.home) {
            item = state.masterData.home[i].data.find(itm => itm.id.toString() === id && (type === 'movie' ? !Object.keys(itm).includes("seasons") : Object.keys(itm).includes("seasons")))
            if (item !== undefined) {
                break;
            }
        }
    }
    return {
        token: state.user.token,
        item: item,
        type: type
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: 'RESET_APP'}),
        login: (data) => dispatch(login(data)),
        setRecent: (data) => dispatch(setRecent(data)),
        addToList: (data) => dispatch(addToList(data)),
        markAddedToList: (data) => dispatch(markAddedToList(data)),
        removeFromList: (data) => dispatch(removeFromList(data)),
        markRemovedFromList: (data) => dispatch(markRemovedFromList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemData);
