import React from 'react'
import SuccessGIF from '../assets/img/success.gif'
import {Link} from "react-router-dom";
import AuthContainer from "./authContainer";

export default function Success(props) {
    React.useEffect(() => {
        document.title = props.title
        window.scrollTo(0, 0)
    }, [props.title])
    return (
        <AuthContainer>
            <div className="h-100 d-flex-column justify-content-between align-items-center">
                <img src={SuccessGIF} alt="Success" height="50" className={'successGif mb-4 br1'}/>
                <h3 className={'mb-3 fw-300'}>{window.location.search.split('=')[1] === '1' ? 'SignUp Successful' : window.location.search.split('=')[1] === '2' ? "SignUp Request Successfully Sent!" : 'Password Reset Successfuly'}</h3>
                <Link to={'/login'}>
                    <button className="stayConnectedTheme text-white">{window.location.search.split('=')[1] === '2' ? "Back" : "Login Now"}</button>
                </Link>
            </div>
        </AuthContainer>
    )

}