import React from 'react'
import './home.css'
import ListWatch from "./ListWatch";
import {connect} from "react-redux";
import {basicDet} from "../store/reducers/userSlice";
import {setReferral} from "../store/reducers/masterDataSlice";
import MyList from "./MyList";
import {basicDetailURL, referralURL} from "../config/apis";
import ContinueWatch from "./continueWatch";

const LastWatch = React.lazy(() => import('./LastWatch'))

function HomeIndex(props) {

    React.useEffect(() => {
        document.title = props.title;
        window.scrollTo(0, 0);
    }, [props.title])

    React.useEffect(() => {
        fetch(basicDetailURL, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token['token']
            }
        }).then(resp => {
            resp.json().then(data => {
                props.basicDet(data)
            })
        })
        fetch(referralURL, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token['token']
            }
        }).then(resp => {
            resp.json().then(data => {
                props.setReferral(data)
            })
        })
    }, [])
    return (
        <React.Suspense fallback={<div className={'d-center'} style={{height: '30vh'}}>Loading...</div>}>
            <LastWatch/>
            <div className="homeContent">
                <React.Suspense fallback={<div className={'d-center'} style={{height: '30vh'}}>Loading...</div>}>
                    <MyList/>
                    <ContinueWatch/>
                    <ListWatch location={props.location}/>
                </React.Suspense>
            </div>
        </React.Suspense>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
        basicDetails: state.user.basicDetails,
    }
}

export default connect(mapStateToProps, {basicDet, setReferral})(HomeIndex);