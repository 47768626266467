import React from 'react'
import {isMobile} from "react-device-detect";
import {contactUsUrl} from "../config/apis";
import Modal from "../components/modal";
import SuccessGIF from '../assets/img/success.gif'

function ContactUs(props) {
    React.useEffect(() => {
        document.title = props.title;
        window.scrollTo(0, 0);
    })
    const [isShown, setIsShown] = React.useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        fetch(contactUsUrl, {
            method: 'POST',
            body: formData
        }).then(resp => {
            setIsShown(true)
            e.target.reset()
        })
    }
    return (
        <section className={"container my-5"}>
            <div className="header-margin">
                <div className={"bg-themeMuted br2 " + [isMobile ? 'p-3' : 'p-4']}>
                    <h1 className={'text-transform-capitalize'}>Contact Us</h1>
                    <form onSubmit={onSubmit} className={"mt-2"}>
                        <label>Full Name</label>
                        <input type="text" name={"name"} placeholder={"Full Name"} className={'mb-2'} required/>
                        <div className={isMobile ? "d-block" : "d-flex align-items-center justify-content-between mb-2"}>
                            <div className={isMobile ? 'mb-2' : "w-48"}>
                                <label>Phone Number</label>
                                <input type="tel" name={"phone"} placeholder={"Phone"} required/>
                            </div>
                            <div className={isMobile ? 'mb-2' : "w-48"}>
                                <label>Email Address</label>
                                <input type="email" name={"mail"} placeholder={"Email"} required/>
                            </div>
                        </div>
                        <div className="mb-2">
                            <label>Subject</label>
                            <input type="text" name={"subject"} placeholder={"Subject"} required/>
                        </div>
                        <div>
                            <label>Email Address</label>
                            <textarea placeholder={"Description"} name={"description"} rows={5}/>
                        </div>
                        <button className="stayConnectedTheme text-white w-100 mt-2">
                            Send
                        </button>
                    </form>
                    <div className={'mt-4 ' + [isMobile ? 'd-block' : 'd-flex justify-content-between']}>
                        <div className={isMobile ? 'mb-2' : 'w-48'}>
                            <h5 className={'mb-1'}>Address</h5>
                            <p>STAY CONNECTED TV, INC.<br/>
                                11720 Amber Park Dr<br/>
                                Ste 160 PMB 1081,<br/>
                                Alpharetta, GA 30009</p>
                        </div>
                        <div className={isMobile ? '' : 'w-48'}>
                            <h5 className={'mb-1'}>Contact Details</h5>
                            <p>Email: support@StayConnectedTV.com</p>
                        </div>
                    </div>
                </div>
            </div>
            {isShown
                ? <Modal onClose={() => setIsShown(false)}>
                    <img src={SuccessGIF} alt="Success" className={'successGif br2 mb-4'}/>
                    <p className={"text-center"}>Thank you for contacting us. We'll get back to you soon.</p>
                </Modal>
                : <></>
            }
        </section>
    )
}

export default ContactUs;
