export const Data = [
    {
        TermsAndConditionHeading: "DESCRIPTION OF SERVICE AND ACCEPTANCE OF TERMS OF USE",
        TermsAndConditionDetails: "Stay Connected Tv, LLC (\"Stay Connected Tv,\" \"we,\" or \"us\") provides an online video service offering a selection of television shows, movies, clips, and other content (collectively, the \"Content\"). Our video service, the Content, our player for viewing the Content (the \"Video Player\") and any other features, tools, applications, materials, or other services offered from time to time by Stay Connected Tv in connection with its business, however accessed, are referred to collectively as the \"Stay Connected Tv Services.\"\n\n" +
            "The Content is available for permissible viewing on or through the following (collectively, the \"Properties\"):\n\n" +
            "•\tThe Stayconnectedtv.com website (the \"Stay Connected Tv Site\");\n" +
            "•\tStay Connected Tv's affiliate and distributor websites;\n" +
            "•\tOther websites where users or website operators are permitted to embed the Video Player; and\n" +
            "•\tStay Connected Tv authorized applications, features or devices.\n\n" +
            "Use of the Stay Connected Tv Services (including access to the Content) on the Properties is subject to compliance with these Terms of Use (\"Terms of Use\" or \"Terms\") and any end user license agreement that might accompany a Stay Connected Tv application, feature or device.\n\n" +
            "Certain Stay Connected Tv Services are provided to you free-of-charge. There are some other Stay Connected Tv Services that, if you are interested in accessing, will require payment by you.  It is our pleasure to provide the Stay Connected Tv Services for your personal enjoyment and entertainment in accordance with these Terms of Use. To access and enjoy the Stay Connected Tv Services, you must agree to, and follow, the terms and conditions set forth in these Terms of Use. By visiting the Stay Connected Tv Site or using any of the Stay Connected Tv Services (including accessing any Content) you are agreeing to these Terms. Please take a moment to carefully read through these Terms. It is our goal to provide you with an exceptional user experience, so if you have any questions or comments about these Terms, please contact us at: info@StayConnectedTv.com"
    },
    {
        TermsAndConditionHeading: "CHANGES TO THE TERMS OF USE BY Stay Connected Tv",
        TermsAndConditionDetails: "Stay Connected Tv may amend these Terms of Use at any time by posting the amended Terms of Use on the Stay Connected Tv Site at http://www.Stay Connected Tv.com/terms. If we make a material amendment to these Terms of Use, we will notify you by posting notice of the amendment on the Stay Connected Tv Site. Any material amendment to these Terms of Use shall be effective automatically 30 days after it is initially posted or, for users who register or otherwise provide opt-in consent during this 30-day period, at the time of registration or consent, as applicable."
    },
    {
        TermsAndConditionHeading: "ACCESS AND USE OF THE Stay Connected Tv SERVICES b",
        TermsAndConditionDetails: "Age Limitations:\n\n" +
            "If you are under the age of 13, then you are not permitted to register with Stay Connected Tv or use any feature or other part of the Stay Connected Tv Services that requires registration. In order to subscribe to Stay Connected Tv Plus, you must be at least 18 years of age.\n\n" +
            "You represent that you are at least 13 years of age if you are registering an account, and, if you are a Stay Connected Tv Plus user, that you are at least 18 years of age.\n\n" +
            "Your License:\n\n" +
            "Stay Connected Tv is pleased to grant you a non-exclusive limited license to use the Stay Connected Tv Services on the Properties, including accessing and viewing the Content on a streaming-only basis through the Video Player, for personal, non-commercial purposes as set forth in these Terms.\n\n" +
            "The Content:\n\n" +
            "You may only access and view the Content personally and for a non-commercial purpose in compliance with these Terms. You may not either directly or through the use of any device, software, internet site, web-based service, or other means remove, alter, bypass, avoid, interfere with, or circumvent any copyright, trademark, or other proprietary notices marked on the Content or any digital rights management mechanism, device, or other content protection or access control measure associated with the Content including geo-filtering mechanisms. You may not either directly or through the use of any device, software, internet site, web-based service, or other means copy, download, stream capture, reproduce, duplicate, archive, distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit or retransmit the Content unless expressly permitted by Stay Connected Tv in writing. You may not incorporate the Content into, or stream or retransmit the Content via, any hardware or software application or make it available via frames or in-line links unless expressly permitted by Stay Connected Tv in writing. Furthermore, you may not create, recreate, distribute or advertise an index of any significant portion of the Content unless authorized by Stay Connected Tv. You may not build a business utilizing the Content, whether or not for profit. The Content covered by these restrictions includes without limitation any text, graphics, layout, interface, logos, photographs, audio and video materials, and stills. In addition, you are strictly prohibited from creating derivative works or materials that otherwise are derived from or based on in any way the Content, including montages, mashups and similar videos, wallpaper, desktop themes, greeting cards, and merchandise, unless it is expressly permitted by Stay Connected Tv in writing. This prohibition applies even if you intend to give away the derivative materials free of charge.\n\n" +
            "The Video Player:\n\n" +
            "You may not modify, enhance, remove, interfere with, or otherwise alter in any way any portion of the Video Player, its underlying technology, any digital rights management mechanism, device, or other content protection or access control measure incorporated into the Video Player. This restriction includes, without limitation, disabling, reverse engineering, modifying, interfering with or otherwise circumventing the Video Player in any manner that enables users to view the Content without: (i) displaying visibly both the Video Player and all surrounding elements (including the graphical user interface, any advertising, copyright notices, and trademarks) of the webpage where the Video Player is located; and (ii) having full access to all functionality of the Video Player, including, without limitation, all video quality and display functionality and all interactive, elective, or click-through advertising functionality.\n\n" +
            "Embedding a Video Using the Video Player:\n\n" +
            "Where Stay Connected Tv has incorporated an embed option in connection with Content on the Stay Connected Tv Services, you may embed videos using the Video Player, provided you do not embed the Video Player on any website or other location that (i) contains or hosts content that is unlawful, infringing, pornographic, obscene, defamatory, libelous, threatening, harassing, vulgar, indecent, profane, hateful, racially or ethnically offensive, encourages criminal conduct, gives rise to civil liability, violates any law, rule, or regulation, infringes any right of any third party including intellectual property rights, or is otherwise inappropriate or objectionable to Stay Connected Tv (in Stay Connected Tv's sole discretion), or (ii) links to infringing or unauthorized content (collectively, \"Unsuitable Material\"). You may not embed the Video Player into any hardware or software application, even for non-commercial purposes. Stay Connected Tv reserves the right to prevent embedding to any website or other location that Stay Connected Tv finds inappropriate or objectionable (as determined by Stay Connected Tv in its sole discretion).\n\n" +
            "Your Responsibilities:\n\n" +
            "In order for us to keep the Stay Connected Tv Services safe and available for everyone to use, we all have to follow the same rules of the road. You and other users must use the Stay Connected Tv Services for lawful, non-commercial, and appropriate purposes only. Your commitment to this principle is critical. You agree to observe the Stay Connected Tv Services, Content, Video Player and embedding restrictions detailed above, and further agree that you will not access the Stay Connected Tv Site or use the Stay Connected Tv Services in a way that:\n\n" +
            "•\tViolates the rights of others, including patent, trademark, trade secret, copyright, privacy, publicity, or other proprietary rights;\n\n" +
            "•\tUses technology or other means to access, index, frame or link to the Stay Connected Tv Services (including the Content) that is not authorized by Stay Connected Tv (including by" +
            " removing, disabling, bypassing, or circumventing any content protection or access control mechanisms intended to prevent the unauthorized download, stream capture, linking, framing, reproduction, access to, or distribution of the Stay Connected Tv Services);\n\n" +
            "•\tInvolves accessing the Stay Connected Tv Services (including the Content)through any automated means, including \"robots,\" \"spiders,\" or \"offline readers\" (other than by" +
            " individually performed searches on publicly accessible search engines for the sole purpose of, and solely to the extent necessary for, creating publicly available search indices - but not caches or archives - of the Stay Connected Tv Services and excluding those search engines or indices that host, promote, or link primarily to infringing or unauthorized content);\n\n" +
            "•\tIntroduces viruses or any other computer code, files, or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications" +
            " equipment;\n\n" +
            "•\tDamages, disables, overburdens, impairs, or gains unauthorized access to the Stay Connected Tv Services, including Stay Connected Tv's servers, computer network, or user" +
            " accounts;\n\n" +
            "•\tRemoves, modifies, disables, blocks, obscures or otherwise impairs any advertising in connection with the Stay Connected Tv Services (including the Content);\n\n" +
            "•\tUses the Stay Connected Tv Services to advertise or promote services that are not expressly approved in advance in writing by Stay Connected Tv;\n\n" +
            "•\tCollects personally identifiable information in violation of Stay Connected Tv's Privacy Policy;\n\n" +
            "•\tEncourages conduct that would constitute a criminal offense or give rise to civil liability;\n\n" +
            "•\tViolates these Terms or any guidelines or policies posted by Stay Connected Tv;\n\n" +
            "•\tInterferes with any other party's use and enjoyment of the Stay Connected Tv Services; or\n\n" +
            "•\tAttempts to do any of the foregoing.\n\n" +
            "If Stay Connected Tv determines in its sole discretion that you are violating any of these Terms, we may (i) notify you, and (ii) use technical measures to block or restrict your access or use of the Stay Connected Tv Services. In either case, you agree to immediately stop accessing or using in any way (or attempting to access or use) the Stay Connected Tv Services, and you agree not to circumvent, avoid, or bypass such restrictions, or otherwise restore or attempt to restore such access or use.\n\n" +
            "No Spam/Unsolicited Communications:\n\n" +
            "We know how annoying and upsetting it can be to receive unwanted email or instant messages from people you do not know. Therefore, no one may use the Stay Connected Tv Services to harvest information about users for the purpose of sending, or to facilitate or encourage the sending of, unsolicited bulk or other communications. You understand that we may take any technical remedies to prevent spam or unsolicited bulk or other communications from entering, utilizing, or remaining within our computer or communications networks. If you Post (as defined below in Section 7) or otherwise send spam, advertising, or other unsolicited communications of any kind through the Stay Connected Tv Services, you acknowledge that you will have caused substantial harm to Stay Connected Tv and that the amount of such harm would be extremely difficult to measure. As a reasonable estimation of such harm, you agree to pay Stay Connected Tv $50 for each such unsolicited communication you send through the Stay Connected Tv Services.\n\n" +
            "Downloads.\n\n" +
            " In order to participate in certain Stay Connected Tv Services or access certain Content, you may be notified that it is necessary to download software or other materials or agree to additional terms and conditions. Unless otherwise provided by these additional terms and conditions, they are hereby incorporated into these Terms.\n\n" +
            "Suspension/Discontinuation.\n\n" +
            "We hope not to, but we may change, suspend, or discontinue - temporarily or permanently - some or all of the Stay Connected Tv Services (including the Content and the devices through which the Stay Connected Tv Services are accessed), with respect to any or all users, at any time without notice. You acknowledge that Stay Connected Tv may do so in Stay Connected Tv's sole discretion. You also agree that Stay Connected Tv will not be liable to you for any modification, suspension, or discontinuance of the Stay Connected Tv Services, although if you are a Stay Connected Tv Plus subscriber and Stay Connected Tv suspends or discontinues the Stay Connected Tv Plus service, Stay Connected Tv may, in its sole discretion, provide you with a credit, refund, discount or other form of consideration (for example, we may credit additional days of service to your account) in accordance with Section 4 below. However, if Stay Connected Tv terminates your account or suspends or discontinues your access to Stay Connected Tv Services due to your violation of these Terms, then you will not be eligible for any such credit, refund, discount or other consideration.\n\n" +
            "Internet Access Charges.\n\n" +
            "You are responsible for any costs you incur to access the internet.\n\n" +
            "Customer Service.\n\n" +
            "If we can be of help to you, please do not hesitate to contact our customer service department by email us at customerservices@StayConnectedTv.com. It would be our pleasure to serve you.\n\n"
    },
    {
        TermsAndConditionHeading: "Stay Connected Tv Subscriptions and Billing",
        TermsAndConditionDetails: "While it is our mission to provide users with as many viewing choices as possible, sometimes that involves giving users the choice of paying a fee in order to view Content that Stay Connected Tv would not otherwise be able to make available without charging a fee. As part of that, access to the Content in Stay Connected Tv requires payment by you of a subscription fee.\n\n" +
            "You can find the specific details regarding your subscription to Stay Connected Tv at any time by clicking on Your Account. You agree that your Stay Connected Tv account is for individual use only and your Stay Connected Tv account is limited to only one simultaneous stream at a time. Additionally, you agree that for various reasons, such as restrictions from content licensors and other limitations or considerations from third parties, certain Content that may be accessible through one Property may not be accessible through other Properties.\n\n" +
            "Because the Stay Connected Tv Service is offered in multiple time zones, for consistency, a \"day\" for purposes of these Terms of Use begins at 12:00 a.m. Eastern Time and ends at 11:59 p.m. Eastern Time of that same calendar day.\n\n" +
            "Billing:\n\n" +
            "By signing up for your Stay Connected Tv subscription, you are expressly agreeing that we are authorized to charge you a monthly subscription fee, any other fees for additional services you may purchase, and any applicable taxes in connection with your use of Stay Connected Tv to the credit card or other payment method accepted by Stay Connected Tv (\"Payment Method\") that you provided during registration. If you want to use a different Payment Method than the one you signed up for during registration, or if there is a change in your credit card validity or expiration date, you may edit your Payment Method information by clicking on Your Account. If your Payment Method expires and you do not edit your Payment Method information or cancel your account, you authorize us to continue billing, and you will remain responsible for any uncollected amounts.\n\n" +
            "As used in these Terms of Use, \"billing\" shall indicate either a charge or debit, as applicable, against your Payment Method. The subscription fee will be billed at the beginning of your subscription or expiration of your free trial period, if any, whichever is earlier, and on each monthly renewal thereafter unless and until you cancel your subscription or the account or service is otherwise suspended or discontinued pursuant to these Terms. To see the commencement date for your next renewal period, go to the billing information section on Your Account page.\n\n" +
            "We automatically bill your Payment Method each month on the calendar day corresponding to the commencement of your subscription. However, if you change your Payment Method, this could result in changing the calendar day upon which you are billed. In the event your subscription began on a day not contained in a given month, we bill your Payment Method on the last day of such month. For example, if you became a paying subscriber on January 31, your Payment Method would next be billed on February 28. You acknowledge that the amount billed each month may vary due to promotional offers, changes in your subscription plan, and changes in applicable taxes, and you authorize us to charge your Payment Method for the corresponding amounts. If Stay Connected Tv changes the subscription fee or other charges for Stay Connected Tv from time to time, we will give you advance notice of these changes by email. However, we will not be able to notify you of changes in any applicable taxes.\n\n" +
            "All fees and charges are nonrefundable except in connection with your early cancellation of the account as further described in \"Ongoing Subscription\" below. Very rarely, if there are special circumstances where Stay Connected Tv determines it is appropriate (e.g., the Stay Connected Tv service is unavailable for days due to technical difficulties), we may provide credits to affected subscribers. The amount and form of such credits, and the decision to provide them, are at Stay Connected Tv's sole and absolute discretion, and the provision of credits in one instance does not entitle anyone to credits in the future under similar or different circumstances.\n\n" +
            "Stay Connected Tv Gift Cards:\n\n" +
            "You may purchase gift cards redeemable for the Stay Connected Tv service through the Stay Connected Tv Site by using a Payment Method previously provided to us or a different Payment Method. Stay Connected Tv gift cards may not be used in conjunction with or in addition to any special offer unless the terms of the special offer expressly indicate otherwise. The purchase and redemption of Stay Connected Tv gift cards are subject to the Stay Connected Tv Gift Card Terms and Conditions.\n\n" +
            "Ongoing Subscription and Cancellation\n\n" +
            "Your Stay Connected Tv subscription will continue in effect on a month-to-month basis unless and until you cancel your subscription or the account or service is otherwise suspended or discontinued pursuant to these Terms. You must cancel your subscription before it renews each month in order to avoid the next month's billing. We will bill the monthly subscription fee plus any applicable taxes to the Payment Method you provide to us during registration (or to a different Payment Method if you change your account information). If you cancel your subscription during the first month, you will not receive a refund for that first month but, unless you elect to cancel your subscription using the \"Cancel Today\" option, you will continue to have access to Stay Connected Tv for the remainder of that month. If you cancel your subscription after the first month, then your access to Stay Connected Tv will cease for the remainder of that month, and you will receive a refund prorated for the number of days remaining in the month during which you cancelled. You can cancel your account by going to Your Account, choosing to \"edit\" your subscription under \"Billing Information\" and clicking on the \"cancel\" option.\n\n" +
            "Unpaid Amounts:\n\n" +
            "In order to sustain the Stay Connected Tv service, it is important that each user of the Stay Connected Tv Plus service honor the payment obligations to which the user agreed. Accordingly, we reserve the right to pursue any amounts you fail to pay in connection with Stay Connected Tv. You will remain liable to Stay Connected Tv for all such amounts and all costs we incur in connection with the collection of these amounts, including, without limitation, collection agency fees, reasonable attorneys' fees, and arbitration or court costs.\n\n" +
            "Free Trials:\n\n" +
            "On occasion, we may be authorized to offer the use of Stay Connected Tv through free trials for certain qualifying users. If we offer you a free trial, the specific terms of your free trial will be stated in the marketing material describing the particular free trial. We are unable to accommodate combining free trials with any other offers. To view the specific details regarding your free trial, if any, click on Your Account.\n\n" +
            "Once your free trial period ends, we will begin billing your Payment Method for monthly subscription fees corresponding to your subscription plan (plus any applicable taxes), unless you cancel prior to the end of your free trial. For that reason, unless otherwise indicated in the free trial description, you may be asked to set up a valid Payment Method when redeeming a free trial offer.\n\n" +
            "It is very important to understand that you will not receive a notice from Stay Connected Tv that your free trial subscription has ended or that your paying subscription has begun. If you wish to avoid charges to your Payment Method, you must cancel Stay Connected Tv Plus prior to midnight Eastern Time on the last day of your free trial period. You can cancel your account by going to Your Account, choosing to \"edit\" your subscription under \"Billing Information\" and clicking on the \"cancel\" option. We will continue to bill your Payment Method on a monthly basis for your subscription plan until you cancel or the account or service is otherwise suspended or discontinued pursuant to these Terms. You may cancel your subscription at anytime. To the extent Stay Connected Tv Plus subscriptions are offered in conjunction with purchases of, or payment for, third party products or services, you agree that Stay Connected Tv will not be liable to you for any claims arising out of or related to your purchase or use of such third party products or services.\n\n" +
            ""
    },
    {
        TermsAndConditionHeading: "ACCOUNTS AND REGISTRATION",
        TermsAndConditionDetails: "We may from time to time offer various features that require registration or the creation of an account with Stay Connected Tv. If at any time you choose to register or create an account with us, the additional terms and conditions set forth below also will apply.\n\n" +
            "All registration information you submit must be accurate and updated. Please keep your password confidential. You will not have to reveal it to any Stay Connected Tv representative. You are responsible for all use on your account, including unauthorized use by any third party, so please be very careful to guard the security of your password. Please notify us by emailing us at customerservice@Stayconnectedtv.com as soon as you know of, or suspect any unauthorized use of, your account. Please also make sure to notify us if your registration information changes, in case we need to contact you.\n\n" +
            "You may terminate your account by going to Your Account, and choosing the \"delete my account\" option. Please identify your account and provide a valid reply email address in the event we require additional information to terminate your account. We reserve the right to immediately terminate or restrict your account or your use of the Stay Connected Tv Services or access to Content at any time, without notice or liability, if Stay Connected Tv determines in its sole discretion that you have breached these Terms of Use, violated any law, rule, or regulation, engaged in other inappropriate conduct, or for any other business reason. We also reserve the right to terminate your account or your use of the Stay Connected Tv Services or access to Content if such use places an undue burden on our networks or servers. Of course, we would prefer to avoid such termination; therefore, we may use technology to limit activities, such as the number of calls to the Stay Connected Tv servers being made or the volume of User Material (as defined below in Section 7) being Posted, and you agree to respect these limitations and not take any steps to circumvent, avoid, or bypass them."
    },
    {
        TermsAndConditionHeading: "COLLECTION AND USE OF PERSONAL INFORMATION",
        TermsAndConditionDetails: "For information about Stay Connected Tv's policies and practices regarding the collection and use of your personally identifiable information, please read Stay Connected Tv's Privacy Policy located here. The Privacy Policy is incorporated by reference and made part of these Terms of Use. Thus, by agreeing to these Terms of Use, you agree that your presence on the Stay Connected Tv Site and use of the Stay Connected Tv Services on any of the Properties are governed by the Stay Connected Tv Privacy Policy in effect at the time of your use."
    },
    {
        TermsAndConditionHeading: "USER REVIEWS, COMMENTS, AND OTHER MATERIAL",
        TermsAndConditionDetails: "Your Posts:\n\n" +
            "As part of the Stay Connected Tv Services, users may have an opportunity to publish, transmit, submit, or otherwise post (collectively, \"Post\") reviews, comments, or other materials (collectively, \"User Material\"). In order to keep the Stay Connected Tv Services enjoyable for all of our users, you must adhere to the rules below.\n\n" +
            "Please choose carefully the User Material that you Post. Please limit yourself to User Material directly relevant to the Stay Connected Tv Services. Moreover, you must not Post User Material that: (i) contains Unsuitable Material (as defined above in Section 3); or (ii) improperly claims the identity of another person. Please note that we use your first and last name as your user ID and therefore your first and last name will appear to the public each time you Post. We advise that you do not, and you should also be careful if you decide to, Post additional personally identifiable information, such as your email address, telephone number, or street address.\n\n" +
            "You must be, or have first obtained permission from, the rightful owner of any User Material you Post. By submitting User Material, you represent and warrant that you own the User Material or otherwise have the right to grant Stay Connected Tv the license provided below. You also represent and warrant that the Posting of your User Material does not violate any right of any party, including privacy rights, publicity rights, and intellectual property rights. In addition, you agree to pay for all royalties, fees, and other payments owed to any party by reason of your Posting User Material. Stay Connected Tv will remove all User Material if we are properly notified that such User Material infringes on another person's rights. You acknowledge that Stay Connected Tv does not guarantee any confidentiality with respect to any User Material.\n\n" +
            "By Posting User Material, you are not forfeiting any ownership rights in such material to Stay Connected Tv. After Posting your User Material, you continue to retain all of the same ownership rights you had prior to Posting. By Posting your User Material, you grant Stay Connected Tv a limited license to use, display, reproduce, distribute, modify, delete from, add to, prepare derivative works of, publicly perform, and publish such User Material through the Stay Connected Tv Services worldwide, including on or through any Property, in perpetuity, in any media formats and any media channels now known or hereinafter created. The license you grant to Stay Connected Tv is non-exclusive (meaning you are not prohibited by us from licensing your User Material to anyone else in addition to Stay Connected Tv), fully-paid, royalty-free (meaning that Stay Connected Tv is not required to pay you for the use of your User Material), and sublicensable (so that Stay Connected Tv is able to use its affiliates, subcontractors, and other partners, such as internet content delivery networks, to provide the Stay Connected Tv Services). By Posting your User Material, you also hereby grant each user of the Stay Connected Tv Services a non-exclusive, limited license to access your User Material, and to use, display, reproduce, distribute, and perform such User Material as permitted through the functionality of the Stay Connected Tv Services and under these Terms of Use.\n\n" +
            "Third Party Posts:\n\n" +
            "Despite these restrictions, please be aware that some material provided by users may be objectionable, unlawful, inaccurate, or inappropriate. Stay Connected Tv does not endorse any User Material, and User Material that is Posted does not reflect the opinions or policies of Stay Connected Tv. We reserve the right, but have no obligation, to monitor User Material and to restrict or remove User Material that we determine, in our sole discretion, is inappropriate or for any other business reason. In no event does Stay Connected Tv assume any responsibility or liability whatsoever for any User Material, and you agree to waive any legal or equitable rights or remedies you may have against Stay Connected Tv with respect to such User Material. You can help us tremendously by notifying us of any inappropriate User Material you find. If a \"report\" feature through the Stay Connected Tv Services is not available for a specific instance of inappropriate User Material, please email customerservice@Stayconnectedtv.com (subject line: \"Inappropriate User Material\")."
    },
    {
        TermsAndConditionHeading: "LINKED DESTINATIONS AND ADVERTISING",
        TermsAndConditionDetails: "Third Party Destinations:\n\n" +
            "If we provide links or pointers to other websites or destinations, you should not infer or assume that Stay Connected Tv operates, controls, or is otherwise connected with these other websites or destinations. When you click on a link within the Stay Connected Tv Services, we will not warn you that you have left the Stay Connected Tv Services and are subject to the terms and conditions (including privacy policies) of another website or destination. In some cases, it may be less obvious than others that you have left the Stay Connected Tv Services and reached another website or destination. Please be careful to read the terms of use and privacy policy of any other website or destination before you provide any confidential information or engage in any transactions. You should not rely on these Terms to govern your use of another website or destination.\n\n" +
            "Stay Connected Tv is not responsible for the content or practices of any website or destination other than the Stay Connected Tv Site, even if it links to the Stay Connected Tv Site and even if the website or destination is operated by a company affiliated or otherwise connected with Stay Connected Tv. By using the Stay Connected Tv Services, you acknowledge and agree that Stay Connected Tv is not responsible or liable to you for any content or other materials hosted and served from any website or destination other than the Stay Connected Tv Site.\n\n" +
            "Advertisements:\n\n" +
            "Stay Connected Tv takes no responsibility for advertisements or any third party material Posted on any of the Properties, nor does it take any responsibility for the products or services provided by advertisers. Any dealings you have with advertisers found while using the Stay Connected Tv Services are between you and the advertiser, and you agree that Stay Connected Tv is not liable for any loss or claim that you may have against an advertiser."
    }, {
        TermsAndConditionHeading: "TRADEMARKS",
        TermsAndConditionDetails: "Stay Connected Tv, the Stay Connected Tv logo, www.Stay Connected Tv.com, and other Stay Connected Tv marks, graphics, logos, scripts, and sounds are trademarks of Stay Connected Tv. None of the Stay Connected Tv trademarks may be copied, downloaded, or otherwise exploited."
    },
    {
        TermsAndConditionHeading: "UNSOLICITED SUBMISSIONS",
        TermsAndConditionDetails: "It is Stay Connected Tv's policy not to accept unsolicited submissions, including scripts, story lines, articles, fan fiction, characters, drawings, information, suggestions, ideas, or concepts. Stay Connected Tv's policy is to delete any such submission without reading it. Therefore, any similarity between an unsolicited submission and any elements in any Stay Connected Tv creative work, including a film, series, story, title, or concept, would be purely coincidental."
    },
    {
        TermsAndConditionHeading: "DISCLAIMER OF WARRANTIES, LIMITATION OF LIABILITY AND INDEMNITY",
        TermsAndConditionDetails: "WHILE WE DO OUR BEST TO PROVIDE THE OPTIMAL PERFORMANCE OF THE Stay Connected Tv SERVICES, YOU AGREE THAT USE OF THE Stay Connected Tv SERVICES IS AT YOUR OWN RISK. THE Stay Connected Tv SERVICES, INCLUDING THE Stay Connected Tv SITE AND THE OTHER PROPERTIES, THE CONTENT, THE VIDEO PLAYER, USER MATERIAL, AND ANY OTHER MATERIALS CONTAINED ON OR PROVIDED THROUGH THE PROPERTIES, ARE PROVIDED \"AS IS\" AND, TO THE FULLEST EXTENT PERMITTED BY LAW, ARE PROVIDED WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, Stay Connected Tv DOES NOT MAKE ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, COMPLETENESS, AVAILABILITY, SECURITY, COMPATIBILITY OR NONINFRINGEMENT; OR THAT THE Stay Connected Tv SERVICES WILL BE UNINTERRUPTED, FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS, ACCURATE, ERROR FREE, OR RELIABLE.\n\n" +
            "IN NO EVENT SHALL Stay Connected Tv OR ITS AFFILIATES, SUCCESSORS, AND ASSIGNS, AND EACH OF THEIR RESPECTIVE INVESTORS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND SUPPLIERS (INCLUDING DISTRIBUTORS AND CONTENT LICENSORS) (COLLECTIVELY, THE \"Stay Connected Tv PARTIES\"), BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR OTHER DAMAGES, INCLUDING LOSS OF PROFITS, ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF THE Stay Connected Tv SERVICES (INCLUDING ANY INFORMATION, PRODUCTS, OR SERVICES ADVERTISED IN, OBTAINED ON, OR PROVIDED THROUGH THE PROPERTIES), WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHER THEORY, EVEN IF THE Stay Connected Tv PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE EXCEED THE AMOUNT PAID BY YOU TO US, IF ANY, FOR ACCESSING OR PARTICIPATING IN ANY ACTIVITY RELATED TO USE OF THE Stay Connected Tv SERVICE OR $50 (WHICHEVER IS LESS).\n\n" +
            "YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS THE Stay Connected Tv PARTIES FROM AND AGAINST ANY AND ALL LIABILITIES, CLAIMS, DAMAGES, EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES AND COSTS), AND OTHER LOSSES ARISING OUT OF OR IN ANY WAY RELATED TO YOUR BREACH OR ALLEGED BREACH OF THESE TERMS OR YOUR USE OF THE Stay Connected Tv SERVICES (INCLUDING YOUR USE OF THE CONTENT). Stay Connected Tv RESERVES THE RIGHT, AT OUR OWN EXPENSE, TO EMPLOY SEPARATE COUNSEL AND ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU."
    },
    {
        TermsAndConditionHeading: "NOTICE AND PROCEDURE FOR CLAIMS OF COPYRIGHT INFRINGEMENT",
        TermsAndConditionDetails: "If you believe that any Content, User Material, or other material provided through the Stay Connected Tv Services, including through a link, infringes your copyright, you should notify Stay Connected Tv of your infringement claim in accordance with the procedure set forth below.\n\n" +
            "We will process each notice of alleged infringement that Stay Connected Tv receives and take appropriate action in accordance with applicable intellectual property laws. A notification of claimed copyright infringement should be emailed to Stay Connected Tv's copyright agent at info@Stayconnectedtv.com (subject line: \"Stay Connected Tv Takedown Request\"). You may also contact us by mail or facsimile at:\n\n" +
            "Copyright Agent\n" +
            "Stay Connected Tv, LLC\n" +
            "3525 Piedmont Rd.\n" +
            "Bldg. 7 Ste. 300\n" +
            "Atlanta, Ga 30305\n" +
            "Facsimile: (404) 865-1215\n\n" +
            "To be effective, the notification must be in writing and contain the following information: (i) an electronic or physical signature of the person authorized to act on behalf of the owner of an exclusive copyright interest; (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description of where the material that you claim is infringing is located on the Stay Connected Tv Services that is reasonably sufficient to enable Stay Connected Tv to identify and locate the material; (iv) how Stay Connected Tv can contact you, such as your address, telephone number, and email address; (v) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (vi) a statement by you that the above information in your notice is accurate and under penalty of perjury that you are authorized to act on behalf of the copyright owner or the owner of an exclusive right in the material. Emails sent to info@Stayconnectedtv.com for purposes other than communication about copyright infringement may not be answered.\n" +
            "Stay Connected Tv has a policy of terminating repeat infringers in appropriate circumstances."
    },
    {
        TermsAndConditionHeading: "ARBITRATION OF CLAIMS",
        TermsAndConditionDetails: "PLEASE READ THIS CAREFULLY. WE WANT YOU TO KNOW IT AFFECTS YOUR RIGHTS.\n\n" +
            "At Stay Connected Tv, we expect that our world-class customer service team will be able to resolve most issues you may have using the Stay Connected Tv Services. In the unlikely event that we do not meet your satisfaction, we prefer to specify now what each of us should expect in order to avoid any confusion later. Accordingly, you and Stay Connected Tv agree to the following resolution process.\n\n" +
            "To begin with, you agree that that any claim that you might have against us regarding these Terms of Use, the Stay Connected Tv Services, or the Properties must be resolved through binding arbitration before the American Arbitration Association using its Commercial Arbitration Rules. It's important to us that we address any issues you might have promptly. To help us do that, you agree to begin any arbitration within one year after your claim arose; otherwise, your claim is waived. As an exception to this arbitration agreement, Stay Connected Tv is happy to give you the right to pursue in small claims court any claim that is within that court's jurisdiction as long as you proceed only on an individual basis.\n\n" +
            "Because we prefer to resolve our issues with you directly, you agree to arbitrate with Stay Connected Tv only in your individual capacity, not as a representative or member of a class. As such, your claims may not be joined with any other claims and there shall be no authority for any dispute to be arbitrated on a class-action basis or brought by a purported class representative.\n\n" +
            "Rather than force everyone to visit us in Atlanta, if you can demonstrate that arbitration in Atlanta would create an undue burden to you, you are free to initiate the arbitration in your home state. Otherwise, the arbitration hearings will be held in Fulton County, Atlanta.\n\n" +
            "It is important that you understand that the arbitrator's decision will be binding and may be entered as a judgment in any court of competent jurisdiction. If the arbitrator rules against Stay Connected Tv, in addition to accepting whatever responsibility is ordered by the arbitrator, we think it's fair that Stay Connected Tv reimburse your reasonable attorneys' fees and costs.\n\n" +
            "If you're not sure what all of this means, of course please feel free to ask an attorney.\n\n" +
            "Since we always prefer to find ways to satisfy you as quickly and efficiently as possible, before initiating any arbitration proceeding, you agree to first discuss the matter informally with Stay Connected Tv for at least 30 days. To do that, please send your full name and contact information, your concern and your proposed solution by mail to us at: Stay Connected Tv, LLC, 3525 Piedmont Rd, Bldg., 7 Ste. 3000 GA 30305 - Attn: Legal Department.\n\n" +
            "Specifying what happens if an issue arises with one of our users is never a fun topic. But we pride ourselves on transparency and we thank you in advance for understanding why it’s important that you and Stay Connected Tv agree on the process described in this Section.\n\n" +
            ""
    },
    {
        TermsAndConditionHeading: "GENERAL INFORMATION",
        TermsAndConditionDetails: "International Use. We are a company based in the United States. Stay Connected Tv's goal is to bring you as much Content as is legally available. That said, we are limited by the rights that our content licensors grant to us. Using technologies to access the Content from territories where Stay Connected Tv does not have rights or does not offer services is prohibited. Stay Connected Tv is not accessible through any devices from outside the United States.\n\n" +
            "Export Controls. Software and the transmission of applicable technical data, if any, in connection with the Stay Connected Tv Services are subject to export controls. You agree to comply with all applicable laws regarding software and the transmission of technical data exported from the United States or the country in which you reside.\n\n" +
            "Choice of Law and Forum. These Terms of Use are governed by, and construed in accordance with, the laws of the State of Atlanta without giving effect to principles of conflicts of law. In the event of a claim by you against Stay Connected Tv, you agree to submit to the exclusive jurisdiction of the courts located in the Atlanta County of the State of Atlanta. In the event of a claim by Stay Connected Tv against you, you agree to submit to the jurisdiction of the courts located where Stay Connected Tv pursues its claim against you, which may include courts in the Atlanta County of the State of Atlanta.\n\n" +
            "No Waiver/Reliance. If you see other parties violating these Terms, we would appreciate it if you would let us know at stayconnectedtv.com subject line: \"TOU Violation\"). Precisely how Stay Connected Tv responds to a party that is violating these Terms will be determined after carefully analyzing all of the facts and circumstances of a particular case. You may not rely upon Stay Connected Tv's precise response with respect to one party or one situation as any indication of what Stay Connected Tv might do with respect to another party or another situation, even if the parties or situations appear to you to be similar. Similarly, if we fail to act in response to a violation of these Terms of Use, you should not assume that we do not object to the violation or even that we are aware of it. In addition, you may not construe a waiver of any provision of these Terms of Use with respect to any party as a waiver of that provision (or any other provision) with respect to either that party or any other party. Further, Stay Connected Tv's decision to delay exercising or enforcing any right or remedy under these Terms of Use shall not constitute a waiver of such right or remedy. Even if Stay Connected Tv acts in a way that appears to you to be inconsistent with these Terms of Use, Stay Connected Tv's action shall not be deemed a waiver or constructive amendment of these Terms.\n\n" +
            "Integration, Amendment, and Severability. Please note that these Terms of Use, including Stay Connected Tv's Privacy Policy which is incorporated in these Terms and any end user license agreement that might accompany Stay Connected Tv authorized applications, features and devices, constitute the entire legal agreement between you and Stay Connected Tv and govern your use of the Stay Connected Tv Services (including your use of the Content) (but excludes any services, if any, that Stay Connected Tv may provide to you under a separate signed written agreement), and completely replaces any prior agreements between you and Stay Connected Tv in relation to the Stay Connected Tv Services. Except as set forth in Section 2 above, these Terms may not be amended or varied except in a writing signed by Stay Connected Tv. Although we understand that electronic or digital signatures are frequently viewed as the equivalent of traditional written signatures these days, for these purposes, a signature or \"signed\" writing or written agreement may not include an electronic or digital signature. These Terms of Use operate to the fullest extent permissible by law. If any provision of these Terms is held to be unlawful, void, or unenforceable, you and we agree that the provision will be deemed severable from these Terms and will not affect the validity and enforceability of any remaining provisions.\n\n" +
            "Thank you for taking the time to read these Terms of Use. By understanding and agreeing to follow these Terms, the experience will be better for all users. Enjoy the Stay Connected Tv Services!"
    },

]