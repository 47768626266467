export const Data = [
    {
        PrivacyPolicyHeading: "Notice and Scope of Our Privacy Practices",
        PrivacyPolicyDetails: "Our goal is to provide clear, prominent, and easily accessible information about our privacy practices. This Policy applies to customer information we collect in the United States and Puerto Rico at Stay Connected TV retail stores (\"stores\") and through our websites (for purposes of this Policy, \"websites\" includes our mobile applications)."
    },
    {
        PrivacyPolicyHeading: "Our Pledge of Accountability",
        PrivacyPolicyDetails: "Stay Connected TV expects our associates and business partners to manage your personal information properly and maintain your trust. We have designated associates who are accountable for compliance with this Privacy Policy."
    },
    {
        PrivacyPolicyHeading: "Information We Collect",
        PrivacyPolicyDetails: "We collect personal information about you to deliver the products and services you request and to help improve your viewing experience. We strive to limit the amount of personal information collected to support the intended purpose of the collection."
    },
    {
        PrivacyPolicyHeading: "Information You Give Us",
        PrivacyPolicyDetails: "We collect personal information from you in a variety of ways when you interact with Stay Connected TV.  Some examples are when you:\n\n" +
            "Create an account on one of our websites\n" +
            "Create an account on one of our websites\n" +
            "Use a gift registry or create a mobile shopping list\n" +
            "Request customer service or contact us\n" +
            "Submit a Stay Connected TV related story, testimonial, rating or review, or other user-generated content that may be posted on the site\n" +
            "Participate in a contest, sweepstake, promotion, or survey\n" +
            "Otherwise submit personal information to us along with any related content of the communication\n\n" +
            "Personal information is information that identifies you or is reasonably linked to you. The personal information we collect may include contact and payment information like your name, email and physical addresses, phone numbers, and credit and debit card information. When you ask us to ship an order, we may collect information you provide us such as the name, address, and phone number of recipients. For certain transactions, we may be required to collect information to comply with legal requirements"
    },
    {
        PrivacyPolicyHeading: "Information from Other Sources",
        PrivacyPolicyDetails: "We may receive personal information about you from other sources as well. Examples of these sources are entities that can help us correct or supplement our records, improve the quality or personalization of our service to you, and help prevent or detect fraud. In addition, we may collect information from consumer reporting agencies or other service providers if you obtain certain financial products."
    },
    {
        PrivacyPolicyHeading: "Automated Information Collection",
        PrivacyPolicyDetails: "We receive and store certain types of information when you interact with our websites, emails, and online advertising. Our purpose is to allow the websites to work correctly, to evaluate use of the website, and to support website analytics and marketing campaigns. Some examples include:\n\n" +
            "We may collect technical information such as your internet protocol address, your device operating system and browser type, the address of a referring website, if any, and the path you take through our websites.\n\n" +
            "We use cookies to recognize you as you use or return to our sites. This is done so that we can provide a continuous and more personalized shopping experience for you. A cookie is a small text file that a website or email may save to your browser and store on your hard drive.\n\n" +
            "We may also use web beacons. Beacons allow us to know if a certain page was visited, an email was opened, or if ad banners on our website and other sites were effective.\n\n" +
            "We operate cameras in stores for security and operational purposes, such as to measure traffic patterns. Except for security purposes, we do not use these cameras to identify you personally."
    },
    {
        PrivacyPolicyHeading: "How We Use Your Information",
        PrivacyPolicyDetails: "Stay Connected TV uses your information to offer and provide our products and services and to support our core business functions. These include order or service fulfillment, internal business processes, marketing, authentication, loss and fraud prevention, public safety and legal functions. Some examples include:\n\n" +
            "To fulfill your requests for products and services and communicate with you about those requests\n" +
            "To register and service your account\n" +
            "To administer surveys, sweepstakes, contests, and promotions\n" +
            "To help us improve and customize our service offerings, websites, and advertising\n" +
            "To send you information about our products, services, and promotions; and\n" +
            "To protect the security or integrity of our websites and our business\n\n" +
            "To accomplish these purposes, we may combine personal and non-personal information we collect with offline information, including information from third parties. We may transfer or disclose your information within our corporate family of companies for these purposes."
    },
    {
        PrivacyPolicyHeading: "How We Disclose Your Information Outside Stay Connected TV",
        PrivacyPolicyDetails: "Stay Connected TV does not sell or rent your personal information to third parties. We only disclose your personal information with your prior consent or under the following limited circumstances."
    },
    {
        PrivacyPolicyHeading: "Service Providers",
        PrivacyPolicyDetails: "We may disclose or otherwise make available personal information about you to service providers that help with our business operations. Examples are shipping vendors, billing and refund vendors, payment card processors, and companies that help us improve our product and service offerings and our websites. Your personal information may be stored and processed by our service providers in the United States or other locations where the service providers or Stay Connected TV maintain facilities. We require our service providers to keep your information secure. In addition, our service providers may not use or disclose personal information for any purpose other than providing the services on our behalf."
    },
    {
        PrivacyPolicyHeading: "Legal Requirements and Protection of Our Company and Others",
        PrivacyPolicyDetails: "We may disclose your information in other special circumstances. These include situations when the sharing is necessary to protect the safety, property, or other rights of Stay Connected TV, our customers, our associates, or any other person, or where otherwise required by law. Examples include protecting the health or safety of customers, or addressing crimes committed on Stay Connected TV websites. Stay Connected TV may provide your personal information in response to a search warrant or other legally valid inquiry or order, or to an investigative body in the case of a breach of an agreement or contravention of law, or as otherwise required by applicable law."
    },
    {
        PrivacyPolicyHeading: "Marketing Purposes",
        PrivacyPolicyDetails: "From time to time with your consent, we may disclose information with carefully selected companies who may offer you products and services of interest."
    },
    {
        PrivacyPolicyHeading: "Business Transfers",
        PrivacyPolicyDetails: "We may transfer any information we have about you in connection with a merger or sale (including transfers made as part of insolvency or bankruptcy proceedings) involving all or part of Stay Connected TV or as part of a corporate reorganization or stock sale or other change in corporate control."
    },
    {
        PrivacyPolicyHeading: "Data Collection, Use, or Disclosure",
        PrivacyPolicyDetails: "Stay Connected TV collects, uses, or discloses your personal information consistent with this policy. The method of obtaining consent is appropriate for the type of personal information being collected, used, or disclosed. For example, we will obtain your express consent (verbal, written, or electronic agreement) to collect, use, or disclose personal health information in accordance with law. In other situations, we consider consent to be implied, such as to use your email for marketing purposes, unless you opt out of these communications such as by unsubscribing."
    },
    {
        PrivacyPolicyHeading: "Marketing Preferences",
        PrivacyPolicyDetails: "We want to communicate with you in ways you want to hear from us. Examples are newsletters, special offers, and new product announcements. We use the following standards for marketing communications:\n\n" +
            "We use an opt-in standard for phone and text messages. We also use an opt-in standard for sharing personal information with third parties for marketing purposes. Opt-in means we will only conduct the activity with your express consent.\n\n" +
            "We use an opt-out standard for email or for other communications like mail. Opt-out means we consider your consent is implied, but will stop the activity on your request.\n\n" +
            "If you contact us by email or mail, please be sure to include your full name, the types of communications you would like to receive or not receive, and your related contact information. For instance, if you would like to opt-out of mail, include your mailing address. Please allow sufficient time for your preferences to be processed. It may take up to 10 days to process your requests related to email and 4-6 weeks for other requests.\n\n" +
            "Please be aware that, even if you have opted out of receiving marketing communications from us, we may still contact you for transactional purposes. Some examples are contacts for customer service, product information, service or reminder notices, or recalls. We may also need to contact you with questions or information regarding your order, such as regarding order status, shipment, or refunds."
    },
    {
        PrivacyPolicyHeading: "Online Operations and Advertising",
        PrivacyPolicyDetails: "You may exercise choices related to our online operations and advertising. For instance, you can choose to browse our websites without accepting cookies Please know that cookies allow us to recognize you from page to page, and they support your transactions with us. Without cookies enabled, you will still be able to browse our websites, but will not be able to complete a purchase or take advantage of certain website features.\n\n" +
            "You may also opt-out of certain online advertising called interest-based advertising. Interest-based ads are ads that are served to you based on your browsing behavior. The goal is to show you ads more tailored to your interests.\n\n" +
            "We may use how you browse and shop in order to show you advertisements on our website that are more relevant to your interests. As an example, if you browse for electronics with us or other sites, we may show you ads for electronics as you browse our website. Advertisements may relate to Stay Connected TV or our advertising partners. We do not disclose personal data about you with advertising partners to place an ad for them. We only retain data related to the ads for legitimate business purposes.\n\n" +
            "In addition, we belong to ad networks that may use your browsing history across participating websites to show you interest-based advertisements on those websites. You may see ads for Stay Connected TV on participating websites based on how you browse our website and those sites. We do not allow the network provider to provide your browsing behavior on our website to other websites.\n\n" +
            "You may opt-out of either interest-based advertising on our website, or interest-based advertising on other sites, as described above. Other websites you visit may offer their own opt-out methods as well. If you do opt-out of interest-based advertisements, you may still see advertisements on websites you visit, just not ads based on your browsing behavior."
    },
    {
        PrivacyPolicyHeading: "How to Access and Update Your Information",
        PrivacyPolicyDetails: "Stay Connected TV takes reasonable steps to keep your personal information accurate and complete. You can access or update your personal information, including contact or account information, in the following ways:\n\n" +
            "If you have created an account on our websites, log into your account. Once you do, you will be able to enter and update your own contact information and payment information, as well as contact information for recipients you have designated.\n\n" +
            "Contact us at the email or postal address listed in the Contact Us section at the bottom of this Policy. Please include your current contact information, the information you are interested in accessing, and your requested changes. We will provide you the personal information requested if reasonably available, unless it infringes on the privacy of other individuals and subject to reasonable limitations provided by law and internal procedures. Otherwise we will describe the types of information we typically collect. We will provide access and make changes you request, or will provide an explanation of what actions we will be able to take with regard to the request.\n\n" +
            "If you need help accessing your information related to a service provided by a third party, we will assist you by referring you to the third party. Please contact us via the Contact Us section below.\n\n" +
            "If you need assistance accessing records related to your Vision Center, Pharmacy, or financial services information, please visit the section below related to Privacy Protections for Specific Types of Information.\n\n"
    },
    {
        PrivacyPolicyHeading: "How We Secure Your Information",
        PrivacyPolicyDetails: "Securing your information is a company priority. Whether you are shopping on our websites or in our stores, we use reasonable security measures to help protect the confidentiality of personal information."
    },
    {
        PrivacyPolicyHeading: "Online Protections",
        PrivacyPolicyDetails: "Your account information is protected by the password you use to access your online account. Please keep this password confidential. We also use a technology called Secure Sockets Layer (SSL). If your browser is capable of SSL (and most are), your account information will be protected during transport across the Internet. You can see a representation of this when you enter a secure portion of our online website (not including our mobile applications), which is any page containing any of your account information, where an image of a closed lock or a solid key should appear in the bottom bar of your browser window. If you click on this image, a small pop-up window displaying website security information will appear. This display indicates that your personal information is transmitted in encrypted form to a Stay Connected TV web server, not to some unknown or unauthorized server."
    },
    {
        PrivacyPolicyHeading: "Hard Copy and Electronic Storage Protections",
        PrivacyPolicyDetails: "Personal information that is maintained in our offices or stores is subject to physical, administrative, and technical controls as well. Hard copies of sensitive personal information are maintained in locked locations or cabinets with similar restrictions for electronic storage. When disposed of, the information is shredded, destroyed, erased, or otherwise sought to be made unreadable."
    },
    {
        PrivacyPolicyHeading: "Privacy Protections for Specific Types of Information - Financial Transactions",
        PrivacyPolicyDetails: "We protect payment card information we collect during transactions. We comply with industry standards that require safeguards for handling and securing customer information. These include using secure networks; encryption or other protection of cardholder data; physical and technical access controls; monitors and tests of security systems; and information security policies."
    },
    {
        PrivacyPolicyHeading: "Privacy Protections for Specific Types of Information - Privacy of Children Online",
        PrivacyPolicyDetails: "We are committed to protecting children online privacy. Our websites are for a general audience and are not geared towards children. In order to protect the privacy of children, Stay Connected TV websites and mobile applications do not knowingly collect personal information from children under the age of 13 without prior parental consent. Please contact us via the Contact Us section of this Policy if you believe we may have collected information from your child and we will work to delete it."
    },
    {
        PrivacyPolicyHeading: "Privacy Protections for Specific Types of Information - Privacy of Mobile Applications",
        PrivacyPolicyDetails: "Stay Connected TV’s mobile applications may include functionality to allow you to create a shopping list, search or add products to a favorites list using voice input or text.\n\n" +
            "In addition to the information mentioned above, our mobile applications collect certain information automatically, such as the type of mobile device you use, the temporary or persistent unique device identifiers (sometimes called UDID) placed by us or our service providers, the unique identifier assigned by Stay Connected TV to your device, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use our mobile applications.\n\n" +
            "Additionally, our mobile applications collect precise information about the location of your device. You must first provide permission through your device before our mobile applications obtain such location information from technologies like GPS, Wi-Fi, or cell tower proximity (or a combination of these technologies). Stay Connected TV, and our service providers, may use and store this information, in combination with other location-based information such as IP address and billing or postal code provided by you at time of your mobile application registration, to provide enhanced location based services, such as store specific services, search results, and other content. To opt-out of the collection of location data, see below.\n\n" +
            "We may disclose or otherwise make available personal information about you to service providers that help with the operation of our mobile applications. These providers may collect device-specific information, such as a unique device identifier, when you use our website or one of our mobile applications. This information will not be associated in any way with your account at Stay Connected TV or any personal information that identifies who you are. The device identifier is used to improve services within our mobile applications such as barcode scanners or speech recognition. We require our service providers to keep your information secure. In addition, our service providers may not use or disclose personal information for any purpose other than providing the services on our behalf.\n\n" +
            "We may also work with analytics companies to help us understand how our mobile applications are being used, such as the frequency and duration of usage. To protect the anonymity of this information, we use an encryption technology to help ensure that these third parties canít identify you personally based on information they collect from our mobile applications or your use of them. These analytics providers may also have access to your device-specific information such as a unique device identifier. This information will not be associated in any way with your account at Stay Connected TV or any personal information that identifies who you are, and we require these service providers to keep your information secure.\n\n" +
            "There are multiple options for users of our mobile applications to opt-out of the collection and of your precise location information:\n\n" +
            "Opt-out of all information collection by uninstalling our mobile applications ñ You can stop all collection of information by our mobile applications easily by uninstalling the mobile applications. You may use the standard uninstall processes available as part of your mobile device or via the mobile application marketplace or network.\n\n" +
            "Note: If you uninstall the mobile application from your device, the Stay Connected TV unique identifier associated with your device will continue to be stored. If you re-install the application on the same device, Stay Connected TV will be able to re-associate this identifier to your previous transactions.\n\n" +
            "You may at any time opt-out from further allowing us to have access to your location data by adjusting the permissions in your mobile device"
    },
    {
        PrivacyPolicyHeading: "International",
        PrivacyPolicyDetails: "As a global company, Stay Connected TV has separate privacy policies for its international offices. Personal information about our customers may be processed and stored in the United States or any other country in which Stay Connected TV or our service providers maintain facilities. Globally, Stay Connected TV requires our family of companies to protect your personal information, regardless of where it is stored, in accordance with local laws and our policies."
    },
    {
        PrivacyPolicyHeading: "Changes to the Stay Connected TV Privacy Policy",
        PrivacyPolicyDetails: "Please check our privacy Policy periodically for changes. We will also notify you of significant updates and will post the date it was last updated at the bottom of the privacy Policy."
    },
    {
        PrivacyPolicyHeading: "Contact Us",
        PrivacyPolicyDetails: "Email:support@StayConnectedTV.com\n\n" +
            "Contacting the Privacy Office\n\n" +
            "Please feel free to contact the privacy office with any questions or comments about this Policy or about how your information is handled. You can also contact us via the addresses below:\n\n" +
            "Stay Connected TV Corporate\n" +
            "Privacy Office,\n" +
            "2870 Peachtree Rd. NW\n" +
            "Unit 879\n" +
            "Atlanta, GA 30305"
    },
    {
        PrivacyPolicyHeading: "Your California Privacy Rights",
        PrivacyPolicyDetails: "Our privacy Policy describes how we share information for marketing purposes. The Policy and rights apply to all customers, including California residents:\n\n" +
            "We share information with others outside of Stay Connected TV only if we have your express consent (opt-in). \n\n" +
            "We also share information with other businesses within our corporate family, such as iflixtv, LLC and Independent Distribution Group, LLC.\n\n" +
            "Please contact us with any questions, or to request a list of third parties to whom we may disclose information for marketing purposes and the categories of information we may disclose, via the Contact Us section above."
    },
]