import React, {useRef} from "react";
import './index.css';
import {isMobile, isMobileOnly} from "react-device-detect";
import SearchGif from '../assets/gif/search.gif'
import {login} from "../store/reducers/userSlice";
import {connect} from "react-redux";
import {getValidToken, watchPartyInviteUrl, watchPartyUserSearchUrl} from "../config/apis";
import {FaUserCircle} from "react-icons/fa";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../config/firebase";
import InviteBtn from "./components/inviteBtn";
import {b64_to_utf8, utf8_to_b64} from "../config/helper";
import {Link} from "react-router-dom";

const Tag = ({value}) => {
    return (
        <p className={'p-1 bg-theme br1 me-1' + [isMobileOnly ? ' mb-1' : '']}>
            <small>{value}</small>
        </p>
    )
}


function WatchParty(props) {
    const [loading, setLoading] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [feedback, setFeedback] = React.useState()
    const [data, setData] = React.useState([])
    const [sessionID, setSessionID] = React.useState(null)
    const valueRef = useRef();

    const queryString = window.location.search ? window.location.search : ("?" + window.location.pathname.split("?")[1]);
    let urlParams = new URLSearchParams(queryString);
    urlParams = b64_to_utf8(urlParams.get('data'));
    urlParams = urlParams.split(";");
    let url = urlParams[2]

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = props.title;
        return () => {
            if (valueRef.current) {
                cancelWatchParty(valueRef.current)
                window.alert("Your Watch Party Has Been Canceled !")
            }
        }
    }, [])

    React.useEffect(() => {
        valueRef.current = sessionID;
        if (sessionID) {
            const unsub = onSnapshot(doc(db, "watchPartyInvites", sessionID), (doc) => {
                setData(doc.data().invites)
            });
            return unsub
        }
    }, [sessionID])

    function onSubmit(event) {
        event.preventDefault()
        setFeedback()
        let formData = new FormData(event.target)
        let jsonFormData = JSON.parse(JSON.stringify(Object.fromEntries(formData)))
        watchPartySearch(jsonFormData)
    }

    function watchPartySearch(jsonFormData) {
        setLoading(true)
        getValidToken(props.token).then(token => {
            props.login(token)
            let queryStr = Reflect.ownKeys(jsonFormData).map(key => {
                if (key === "email") {
                    let temp = jsonFormData[key].split('@')
                    temp[0] = temp[0].replace('.', '')
                    temp = temp[0] + '@' + temp[1]
                    return `${encodeURIComponent(key)}=${encodeURIComponent(temp)}`
                }
                return `${encodeURIComponent(key)}=${encodeURIComponent(jsonFormData[key])}`
            }).join("&")
            let url = `${watchPartyUserSearchUrl}?${queryStr}`
            fetch(url, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "applicaion/json",
                    "Authorization": "Bearer " + token['token']
                }
            }).then(res => {
                if (res.status === 401) {
                    props.reset()
                }
                if (res.status === 200) {
                    res.json().then(data => {
                        if (data.users.length <= 0) {
                            setFeedback("No User Found")
                        }
                        setUsers(data.users)
                    })
                }
            }).finally(() => setLoading(false))
        })
    }

    function unInviteUser(email) {
        setData(data.filter(user => user.email !== email))
        getValidToken(props.token).then(token => {
            props.login(token)
            fetch(watchPartyInviteUrl, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token['token']
                },
                body: JSON.stringify({sessionID, email})
            }).then(res => {
                if (res.status === 401) {
                    props.reset()
                }
            })
        })
    }

    function cancelWatchParty(sId) {
        getValidToken(props.token).then(token => {
            props.login(token)
            fetch(watchPartyInviteUrl, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token['token']
                },
                body: JSON.stringify({sessionID: sId})
            }).then(res => {
                if (res.status === 401) {
                    props.reset()
                }
                if (res.status === 200) {
                }
            })
        })
    }

    function startWatching() {
        valueRef.current = ""
        props.history.push('/watchParty/' + sessionID + '?data=' + utf8_to_b64(url))
    }

    return (
        <section className="watchParty container">
            <div className={isMobile ? "d-flex-column" : " d-flex"}>
                <div className={"d-flex-column watchPartySearch " + [isMobile ? 'mb-2' : 'flex-3 me-2']}>
                    <form className={'p-3 mb-4 br2 bg-themeMuted'} onSubmit={onSubmit}>
                        <div className={isMobile ? '' : "d-flex"}>
                            <input type="email" placeholder={'Enter Email'} name="email"/>
                            <button className={'small primary text-white ' + [isMobile ? 'd-flex align-items-center w-100 justify-content-center mt-2' : 'ms-2']}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "18" : "24"} height={isMobile ? "18" : "24"} viewBox="0 0 24 24">
                                    <path fill="#ffffff"
                                          d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"/>
                                    <path fill="#ffffff"
                                          d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z"/>
                                </svg>
                                {isMobile ? 'Search' : ''}
                            </button>
                        </div>
                    </form>
                    <div className={"flex-11 overflowY mb-2"}>
                        {
                            loading ?
                                <div className={'my-4 d-flex-column align-items-center justify-content-center'}>
                                    <img src={SearchGif} height={50} alt=""/>
                                    <h4 className="h4 mt-2">Searching...</h4>
                                </div>
                                :
                                (users.length <= 0) ?
                                    <div className={'my-4'}>
                                        {
                                            feedback ?
                                                <h4 className="h4 mb-1">{feedback}</h4>
                                                :
                                                <h4 className="h4 mb-1">Search users to invite them to Watch Party.</h4>
                                        }
                                    </div>
                                    :
                                    users.map((user, index) => {
                                        return (
                                            <div key={index}
                                                 className={'bg-themeMuted watchPartyUserItem br2 mb-2' + [isMobile ? ' p-2 d-flex-column align-items-start' : ' p-3 d-flex align-items-center justify-content-between']}>
                                                <div className={'d-flex align-items-center'}>
                                                    <FaUserCircle className="circle" size="3em"/>
                                                    <div className={'mx-2'}>
                                                        <h6 className="h6">{user.user.first_name} {user.user.last_name}</h6>
                                                        <p className={'text-muted'}>{user.user_name}</p>
                                                    </div>
                                                </div>
                                                <InviteBtn setSessionID={setSessionID} sessionID={sessionID} user={user} data={data}/>
                                            </div>
                                        )
                                    })
                        }
                    </div>
                    <div className={"flex-1"}>
                        <button className={isMobileOnly ? 'w-100' : ''} onClick={startWatching}>Start Watching Now</button>
                    </div>
                </div>
                <div className={"watchPartyUser" + [isMobile ? '' : ' flex-2 ms-2']}>
                    <div className="title pb-2 bg-theme">
                        <h4 className="h4">Users for this Watch Party</h4>
                    </div>
                    <div className={"watchPartyUsers" + [isMobile ? '' : ' pe-2']}>
                        {data.map((item, index) => {
                            return (
                                <div className={'bg-themeMuted watchPartyUserItem br2 my-2' + [isMobile ? ' p-2' : ' p-3']} key={index}>
                                    <div className={'d-flex align-items-center'}>
                                        <button className="small danger square-small p-0 removeBtn" onClick={() => unInviteUser(item.email)}>X</button>
                                        <FaUserCircle className="circle" size="3em"/>
                                        <div className={'flex-1 mx-2'}>
                                            <h6 className="h6">{item.name}</h6>
                                            <p className={'text-muted'}>{item.email}</p>
                                        </div>
                                        <div className={isMobile ? 'd-flex' : 'd-flex-column align-items-end'}>
                                            {
                                                item.status === -1 ?
                                                    <button className={'small success pointer-none'}>Invited</button> :
                                                    item.status === 1 ?
                                                        <button className={'small bg-theme text-success pointer-none'}>Accepted</button>
                                                        :
                                                        item.status === 0 &&
                                                        <button className={'small bg-theme text-danger pointer-none'}>Rejected</button>
                                            }
                                        </div>
                                    </div>
                                    <div className={'mt-2 d-flex flex-wrap'}>
                                        <Tag value={item.type === 1 ? 'Subscribed' : 'Not - Subscribed'}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        reset: () => dispatch({type: "RESET_APP"}),
        login: (data) => dispatch(login(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WatchParty);
